<template>
  <v-col cols="12">

    <div class="login-guide black--text text-center mb-8">
      {{ $t('login.password_change_required') }}
    </div>

    <v-alert class="text-left"
             :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : ''"
             :text="uiComponentProperties.text"
             :value="true"
             v-if="error"
             type="error">
      {{ error }}
    </v-alert>

    <v-text-field  v-model="password"
                   type="password"
                   :label="$t('login.new_password')"
                   autofocus
                   color="primary"
                   hide-details
                   class="mb-8"
                   :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
                   :outlined="uiComponentProperties.outlined" />

    <div v-bind:class="amplifyUI.formField"
         v-for="attribute in options.user.challengeParam.requiredAttributes"
         :attribute="attribute"
         v-bind:key="attribute">
      <div v-bind:class="amplifyUI.inputLabel">{{attribute.charAt(0).toUpperCase() + attribute.slice(1)}}</div>
      <input
        v-bind:class="amplifyUI.input"
        v-model="requiredAttributes[attribute]"
        :placeholder="attribute.charAt(0).toUpperCase() + attribute.slice(1)"
      />
    </div>

    <div class="text-center">
      <v-btn large
             :rounded="uiComponentProperties.rounded"
             class=""
             block
             color="primary"
             @click="change">
        <v-icon left>mdi mdi-send</v-icon>
        {{ $t('login.change_password') }}
      </v-btn>
      <v-btn large
             :rounded="uiComponentProperties.rounded"
             block
             color="primary"
             class="mt-5"
             text
             @click="signIn">
        <v-icon left>mdi mdi-arrow-left</v-icon>
        {{ $t('login.back_to_sign_in') }}
      </v-btn>
    </div>

  </v-col>
</template>
<script>
  import { AmplifyEventBus } from 'aws-amplify-vue'
  import * as AmplifyUI from '@aws-amplify/ui';

  export default {
    name: 'RequireNewPassword',
    props: ['requireNewPasswordConfig', 'uiComponentProperties'],
    data () {
      return {
        user: '',
        error: '',
        password: '',
        logger: {},
        requiredAttributes: {},
        amplifyUI: AmplifyUI
      }
    },
    computed: {
      options() {
        const defaults = {
          header: this.$Amplify.I18n.get('Enter new password'),
          user: {
            challengeParam: {
              requiredAttributes: []
            }
          }
        }
        return Object.assign(defaults, this.requireNewPasswordConfig || {})
      }
    },
    mounted() {
      this.logger = new this.$Amplify.Logger(this.$options.name)
    },
    methods: {
      setError: function(e) {
        if (!e) {
          this.error = '';
          return;
        }
        this.error = this.$Amplify.I18n.get(e.message || e);
        this.logger.error(this.error);
      },
      checkContact(user) {
        this.$Amplify.Auth.verifiedContact(user)
          .then(/*data*/ () => {
            this.setError(false);
            AmplifyEventBus.$emit('localUser', this.user)
            AmplifyEventBus.$emit('authState', 'signedIn')
          })
          .catch((e) => this.setError(e))
      },
      change() {
        this.$Amplify.Auth.completeNewPassword(this.options.user, this.password, this.requiredAttributes)
          .then(/* user */() => {
            if (this.options.user.challengeName === 'SMS_MFA') {
              AmplifyEventBus.$emit('localUser', this.options.user)
              AmplifyEventBus.$emit('authState', 'confirmSignIn')
            } else if (this.options.user.challengeName === 'MFA_SETUP') {
              AmplifyEventBus.$emit('localUser', this.options.user)
              AmplifyEventBus.$emit('authState', 'setMfa')
            } else {
              this.checkContact(this.options.user);
            }
            this.setError(false);
          })
          .catch(err => this.setError(err));
      },
      signIn: function() {
        AmplifyEventBus.$emit('authState', 'signIn')
      },
    }
  }
</script>
