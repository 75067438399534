<template>
  <v-chip :color="chipColor" label :small="!xSmall" :x-small="xSmall" v-if="displayText">
    {{ displayText }}
  </v-chip>
</template>

<script>
export default {
  name: 'HbStatusChip',
  props: {
    type: {
      type: String,
      required: true,
      validator: value => ['result', 'status'].includes(value)
    },
    value: {
      type: String,
      required: true
    },
    xSmall: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    chipColor() {
      if (this.type === 'result') {
        return this.resultColors[this.value] || 'grey';
      } else if (this.type === 'status') {
        return this.statusColors[this.value] || 'grey';
      }
      else {
        return 'grey';
      }
    },
    displayText() {
      if (this.type === 'result') {
        return this.$t(`application.result.${this.value}`);
      } else if (this.type === 'status') {
        return this.$t(`application.status.${this.value}`);
      } else {
        return this.value;
      }
    },
    //TODO: Värit tulee muuttaa oikeiksi
    resultColors() {
      return {
        'NONE': 'grey',
        'RETRACTED': 'orange',
        'REJECTED': 'red lighten-1',
        'APPROVED': 'green',
        'POTENTIAL': 'green darken-1',
      };
    },
    statusColors() {
      return {
        'NEW': 'blue',
        'CHECKED': 'purple lighten-3',
        'CONTACTED': 'yellow darken-1',
        'INTERVIEW': 'lime',
        'INTERVIEWED': 'lime darken-2',
        'HANDLED': 'teal lighten-1'
      };
    }
  }
}
</script>
