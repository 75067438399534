<template>
  <v-app light id="app-wrapper">

    <template v-if="showApplication">
      <template v-if="loadingOverlay">
        <v-fade-transition>
          <div class="loading-overlay">
            <v-row class="fill-height" justify="center" align="center">
              <v-col cols="4" class="text-center">
                <img style="width: 100px; height: auto; opacity: 0.4;" src="/img/hubble-h-logo-pulse.svg" />
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </template>

      <v-snackbar v-model="snackbar" top multi-line :timeout="notification.timeOut" :color="notification.color">
        {{ notification.text }}
        <template v-slot:action>
          <template v-if="notification.showButton">
            <v-btn small outlined text @click="() => { notification.callback(); snackbar = false;}">
              {{ notification.buttonText }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon @click="snackbar = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </template>
      </v-snackbar>
      <!--kommenti foo bar jeje versio2-->
      <v-navigation-drawer
        v-if="shouldMenuBeAvailable"
        v-model="menuOpenState"
        :mini-variant="miniMenu && $vuetify.breakpoint.lgAndUp"
        app
        fixed
        dark
        width="300"
        color="#292933"
      >
        <v-list class="pa-0 elevation-4" light style="background: rgb(245, 246, 250);">
          <v-list-item style="padding: 3px 10px 3px 15px;">

            <v-list-item-content>
              <v-list-item-title class="subheading font-weight-bold">
                <v-row align="center" no-gutters>
                  <img style="height: 30px; width: auto;" src="/img/satahub_logo_2.svg" :title="$t('title')" />
                </v-row>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                @click.stop="toggleMiniMenu"
                small
              >
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-list-item-action>

          </v-list-item>
        </v-list>

        <v-list :dense="$vuetify.breakpoint.mdAndDown">

          <template v-if="$userInfo && $userInfo.tenants.length > 1">
            <TenantSelect :miniMenu="miniMenu" />
          </template>

          <template v-if="filteredPrimaryPages.length > 0">
            <v-list-item
              v-for="(page, index) in filteredPrimaryPages"
              :key="index+'primary'"
              :to="getPageLink(page)"
              @click="closeMenuIfOnSamePage(page.link)"
              exact
            >
              <v-list-item-action>
                <v-icon color="accent darken-2">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredSecondaryPages.length > 0">
            <v-list-item
              v-for="(page, index) in filteredSecondaryPages"
              :key="index+'secondary'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact
            >
              <v-list-item-action>
                <v-icon color="accent darken-2">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider ></v-divider>
          </template>


          <template v-if="filteredTenantAdminPages.length > 0">
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.organization_admin') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredTenantAdminPages"
              :key="index+'tadmin'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact>
              <v-list-item-action>
                <v-icon color="accent darken-2">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider ></v-divider>
          </template>

          <template v-if="filteredAdminPages.length > 0">
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.application_admin') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredAdminPages"
              :key="index+'admin'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact>
              <v-list-item-action>
                <v-icon color="accent darken-2">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider ></v-divider>
          </template>

          <v-list-item :to="{name: 'userprofile'}" @click="closeMenuIfOnSamePage('userprofile')">
            <v-list-item-action>
              <v-icon color="accent darken-2">person</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user_profile.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon color="accent darken-2">logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
              <v-list-item-subtitle class="caption" v-if="$isLoggedIn">{{ $userInfo.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>

      </v-navigation-drawer>

      <v-main>
        <router-view/>
      </v-main>

    </template>
    <template v-else>
      <NoTenantsView></NoTenantsView>
    </template>
  </v-app>
</template>

<script>
  import TenantSelect from "./components/TenantSelect.vue";
  import TenantService from './service/tenant.service';
  import NoTenantsView from "./views/NoTenantsView.vue";
  import UiEventBus from '@/UiEventBus';

  export default {
    components: { TenantSelect, NoTenantsView },
    data() {
      return {
        notification: {
          color: '',
          text: '',
          buttonText: '',
          showButton: false,
          timeOut: -1,
          callback: () => {},
        },
        snackbar: false,
        miniMenu: false,
        bottomNavSelected: '',
        showBottomNav: true,
        menuOpenState: false,
        pages: [
          {
            title: this.$t('dashboard.title'),
            icon: 'mdi mdi-view-dashboard',
            link: 'tenant_dashboard',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
          {
            title: this.$t('online_test_management.title'),
            icon: 'mdi mdi-head-question-outline',
            link: 'online_test_management',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
          {
            title: this.$tc('job_ads.title', 2),
            icon: 'mdi mdi-magnify-scan',
            link: 'job_ads',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
          {
            title: this.$t('applicants.title'),
            icon: 'mdi mdi-account-multiple',
            link: 'applicants',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
          {
            title: this.$t('questions_management.title'),
            icon: 'mdi mdi-crosshairs-question',
            link: 'questions',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
          {
            title: this.$t('customers.title'),
            icon: 'mdi mdi-factory',
            link: 'customers',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
          {
            title: this.$t('communication.title'),
            icon: 'mdi mdi-email',
            link: 'communication',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
        ],
        tenantPages: [
          {
            title: this.$t('tenants.management_title'),
            icon: 'settings',
            link: 'tenant_settings',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: ['tenant_admin'],
            primary: false,
          },
          {
            title: this.$t('tenants.organization_users'),
            icon: 'people',
            link: 'tenant_users',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: ['tenant_admin'],
            primary: false,
          },
        ],
        adminPages: [
          {
            title: this.$t('user_management.title'),
            icon: 'people',
            link: 'users',
            roles: ['ADMIN'],
            primary: false,
          },
          {
            title: this.$t('tenants.title'),
            icon: 'business',
            link: 'tenants',
            roles: ['ADMIN'],
            primary: false,
          },
        ],
      }
    },
    methods: {
      getPageLink(page) {
        const link = {
          name: page.link,
        }

        if ('params' in page) {
          link.params = page.params();
        }
        return link;
      },
      logout() {
        TenantService.clear();
        // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
        this.$Amplify.Auth.signOut().then(() => {
          // this is client side only logout
          this.$clearUserInfo();
          this.$store.commit('setLoadingOverlay', true);
          this.$store.commit('setLoggedIn', false);
          this.$router.push({name: 'login'});
        })
      },
      goToLink(name) {
        this.$router.push({name: name});
      },
      toggleMiniMenu() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          this.miniMenu = !this.miniMenu;
        } else {
          this.$store.commit('toggleMainMenuState');
        }
      },
      closeMenuIfOnSamePage(link) {
        if (this.$vuetify.breakpoint.mdAndDown && this.$route.name === link) {
          this.menuOpenState = false;
        }
      },
      handleSelectedBottomNavItem(name) {
        const isPagePrimary = this.pages.findIndex(p => p.primary && p.link === name) > -1;
        if (isPagePrimary) {
          this.bottomNavSelected = name;
          this.showBottomNav = true;
        } else {
          this.showBottomNav = false;
        }
      },
    },
    watch: {
      $isLoggedIn(newValue, oldValue) {
        console.log('isLoggedInChange', newValue, oldValue);
        if (newValue === true) {
          UiEventBus.$emit('loginSuccess');
        }
      },
      loadingOverlay(to) {
        if (to) {
          setTimeout(() => {
            this.$store.commit('setLoadingOverlay', false);
          }, 2000);
        }
      },
      notificationObject(to) {
        this.notification.color = to.color;
        this.notification.text = to.message;
        this.snackbar = true;
        this.notification.buttonText = to.buttonText;
        this.notification.showButton = to.showButton;
        this.notification.timeOut = to.timeOut;
        this.notification.callback = to.callback;
      },
      menuOpenComputed(to) {
        this.menuOpenState = to;
      },
      menuOpenState(to) {
        if (to !== this.$store.state.mainMenuOpen) {
          this.$store.commit('toggleMainMenuState');
        }
      },
      $route(to, from) {
        if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
        this.handleSelectedBottomNavItem(to.name);
      },
      currentRouteName(val) {
        if (!this.$isLoggedIn && val !== 'login') {
          this.$router.push({name: 'login'});
        }
      },
    },
    computed: {
      showApplication() {
        return !this.$isLoggedIn || this.$isAdmin || (this.$userInfo && this.$userInfo.tenants.length > 0);
      },
      loadingOverlay() {
        return this.$store.state.loadingOverlay;
      },
      shouldMenuBeAvailable() {
        return this.$isLoggedIn;
      },
      notificationObject() {
        return this.$store.state.notificationObject;
      },
      menuOpenComputed() {
        let menuState = true;
        if (this.$vuetify.breakpoint.mdAndDown) {
          menuState = this.$store.state.mainMenuOpen;
        }
        return menuState;
      },
      filteredSecondaryPages() {
        if (!this.$tenant) {
          return [];
        }
        let pages = [];
        this.pages.forEach(page => {
          if (!page.primary) {
            pages.push(page);
          }
        })
        return pages;
      },
      filteredTenantAdminPages() {
        if (this.$tenant && this.$userInfo.roles.indexOf('tenant_admin') !== -1) {
          return this.tenantPages;
        }
        return [];
      },
      filteredAdminPages() {
        if (this.$isAdmin) {
          return this.adminPages;
        }
        return [];
      },
      filteredPrimaryPages() {
        if (!this.$tenant) {
          return [];
        }
        let pages = [];
        this.pages.forEach(page => {
          if (page.primary) {
            pages.push(page);
          }
        })
        return pages;
      },
      currentRouteName() {
        return this.$route.name;
      }
    },
    created() {
      // If application is opened from link, and some other tenant id has been saved in tenant service
      // This sets the one from URL
      if ('tenantId' in this.$router.currentRoute.params) {
        TenantService.setTenant(this.$router.currentRoute.params.tenantId);
      }
    },
    mounted() {
      if (this.$vuetify.breakpoint.lgAndUp) this.menuOpenState = true;
      this.handleSelectedBottomNavItem(this.$route.name);
    },
  }
</script>

<style lang="scss" scoped>
@import "./sass/commonstyles.scss";
</style>
