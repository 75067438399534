<template>
  <hb-basic-page :title="job ? job.name : ''"
                 :loading="loading"
                 :subtle-loading="subtleLoading"
                 :back-button="{show: true, fallback: {name: 'job_ad', params: {id: job.id}}}"
                 name="single_job_application">
    <v-col v-if="!loading" cols="12">
      <v-row>
        <v-card class="ma-3" width="100%">
          <v-row :justify="$vuetify.breakpoint.lgAndDown ? 'start' : 'space-between'" class="d-flex flex-row py-5 px-10">
            <div class="pr-5">
              <v-avatar size="120" color="grey lighten-3">
                <v-img v-if="applicant.profilePictureUrl"
                       :src="applicant.profilePictureUrl"
                       :alt="applicant.firstName"
                       width="100px"
                       height="100px"></v-img>
                <v-icon v-else color="grey lighten-1" size="42">mdi mdi-image</v-icon>
              </v-avatar>
            </div>
            <div :class="$vuetify.breakpoint.xlOnly ? 'flex-grow-1' : ''">
              <v-row no-gutters>
                <h2 class="text-h5">{{ applicant.firstName }} {{ applicant.lastName }}</h2>
                <v-btn text small outlined color="primary" class="ml-3" :to="{name: 'single_applicant', params: { id: applicant.id } }">
                  <v-icon left>mdi-account-circle</v-icon>
                  <div class="caption">{{ $t('applicants.goto_profile') }}</div>
                </v-btn>
                <v-btn :loading="generatingReport" @click="generateReport" class="ml-3" color="primary" outlined text small>
                  <v-icon left>mdi-card-text-outline</v-icon>
                  <div class="caption">{{ $t('application.generate_report') }}</div>
                </v-btn>
              </v-row>
              <div class="my-1"><v-icon size="18" color="secondary" class="mr-1">mdi mdi-cellphone</v-icon> <a :href="`tel:${applicant.phone}`">{{ applicant.phone }}</a></div>
              <div class="my-1"><v-icon size="18" color="secondary" class="mr-1">mdi mdi-email</v-icon> <a :href="`mailto:${applicant.email}`">{{ applicant.email }}</a></div>
              <div class="my-1"><v-icon size="18" color="secondary" class="mr-1">mdi mdi-home</v-icon>{{ applicant.address }} {{ applicant.zipCode }} {{ applicant.city }}</div>
              <div v-if="applicant.tags && applicant.tags.length" id="applicant-tags-container"></div>
              <v-row no-gutters justify="space-between" :class="$vuetify.breakpoint.lgAndDown ? 'flex-column' : ''">
                <div class="mt-3 mr-2">
                  <div class="caption">{{ $t('application.status_label') }}:</div>
                  <v-btn-toggle color="secondary" v-model="currentApplicationStatus" mandatory>
                    <template v-for="status in applicationStatuses">
                      <v-btn :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.mdAndUp" :key="status" :value="status" @click="setApplicationStatus(status)">{{ $t(`application.status.${status}`) }}</v-btn>
                    </template>
                  </v-btn-toggle>
                </div>
                <div class="text-xl-right mt-3">
                  <v-slide-x-transition v-if="currentApplicationStatus === 'HANDLED'" >
                    <div>
                      <div class="caption text-left">{{ $t('application.result_label') }}:</div>
                      <v-btn-toggle color="primary" class="text-right" v-model="currentApplicationResult">
                        <template v-for="result in applicationResults">
                          <v-btn v-if="$t(`application.result.${result}`)"
                                 :x-small="$vuetify.breakpoint.smAndDown"
                                 :small="$vuetify.breakpoint.mdAndUp"
                                 color="primary"
                                 outlined
                                 :key="result"
                                 :value="result"
                                 @click="setApplicationResult(result)">{{ $t(`application.result.${result}`) }}</v-btn>
                        </template>
                      </v-btn-toggle>
                    </div>
                  </v-slide-x-transition>
                </div>
              </v-row>
            </div>
          </v-row>
        </v-card>
      </v-row>
      <v-row no-gutters>
        <!-- Vasen paneeli -->
        <v-col cols="12" md="6" xl="6">
          <v-card :class="$vuetify.breakpoint.mdAndUp ? 'mt-3 mr-3' : 'mt-6'">
            <v-tabs grow show-arrows>
              <v-tab>{{ $t('application.basic_info')}}</v-tab>
              <v-tab>{{ $t('application.files')}}</v-tab>
              <v-tab>{{ $t('application.question_answers')}}</v-tab>
              <v-tab v-if="false">{{ $t('application.messages')}}</v-tab>

              <!--          Perus-->
              <v-tab-item>
                <v-card-text v-if="false">
                  <div class="subtitle-1">{{ $t('application.own_description') }}</div>
                  <p v-if="applicant.ownDescription" :style="{ whiteSpace: 'pre-line'}">{{ applicant.ownDescription }}</p>
                </v-card-text>
                <v-card-text>
                  <div v-if="jobLicenses && jobLicenses.length" >
                    <div class="subtitle-1">{{ $t('application.required_licenses') }}</div>
                    <v-chip-group column multiple>
                      <v-chip
                        small
                        v-for="chip in jobLicenses"
                        :key="chip.code"
                        :value="chip.code"
                        :color="applicant.licenses && applicant.licenses.length && applicant.licenses.some(l => l.license.code === chip.code) ? 'primary' : 'error'"
                      >
                        <v-icon left>{{ applicant.licenses && applicant.licenses.length && applicant.licenses.some(l => l.license.code === chip.code) ? 'mdi mdi-check' : 'mdi-close' }}</v-icon>
                        {{ $te(`licenses.${chip.code}`) ? $t(`licenses.${chip.code}`) : chip.code }}
                      </v-chip>
                    </v-chip-group>
                  </div>
                  <div v-if="jobDrivingLicenses && jobDrivingLicenses.length"  class="mt-3">
                    <div class="subtitle-1">{{ $t('application.required_driving_licenses') }}</div>
                    <v-chip-group column multiple>
                      <v-chip
                        small
                        v-for="chip in jobDrivingLicenses"
                        :key="chip.code"
                        :value="chip.code"
                        :color="applicant.licenses && applicant.licenses.length && applicant.licenses.some(l => l.license.code === chip.code) ? 'primary' : 'error'"
                      >
                        <v-icon left>{{ applicant.licenses && applicant.licenses.length && applicant.licenses.some(l => l.license.code === chip.code) ? 'mdi mdi-check' : 'mdi-close' }}</v-icon>
                        {{ $te(`licenses.${chip.code}`) ? $t(`licenses.${chip.code}`) : chip.code }}
                      </v-chip>
                    </v-chip-group>
                  </div>
                  <div v-if="applicant.licenses && applicant.licenses.length" class="mt-3">
                    <div class="subtitle-1">{{ $t('application.applicant_all_licenses') }}</div>
                    <v-chip-group column multiple>
                      <v-chip
                        small
                        v-for="chip in applicantLicenses"
                        :key="chip.license.code"
                        :value="chip.license.code"
                      >
                        {{ $t(`licenses.${chip.license.code}`) }}
                      </v-chip>
                      <template v-if="applicant.licenses.filter(l => l.license.category === 'DRIVING').length">
                        <v-chip value="DRIVING" small>
                          {{ $t('applicants.driving_licenses') }}: {{ applicant.licenses.filter(l => l.license.category === 'DRIVING').map(l => $te(`licenses.${l.license.code}`) ? $t(`licenses.${l.license.code}`) : l.license.code).join(', ') }}
                        </v-chip>
                      </template>
                    </v-chip-group>
                  </div>
                </v-card-text>
                <v-card-text v-if="applicant.experience && applicant.experience.length > 0">
                  <div class="subtitle-1">{{ $t('application.experience') }}</div>
                  <template v-for="experience in applicant.experience">
                    <div :key="experience.id">
                      <strong v-if="experience.industry">{{ experience.industry.name }}:</strong> {{ experience.years === 0 ? $t(`experience.none`) : experience.years > 5 ? $t(`experience.over_x_years`, {x: 5}) : `${ experience.years } ${$tc('experience.year', experience.years)}` }}
                    </div>
                  </template>
                </v-card-text>

                <v-card-text v-if="applicant.education && applicant.education.length > 0">
                  <div class="subtitle-1">{{ $t('application.education') }}</div>
                  <template v-for="education in applicant.education">
                    <div :key="education.id">
                      <strong v-if="education.industry">{{ education.industry.name }}<template v-if="education.educationLevel">:</template></strong> {{ education.educationLevel && $t(`education.education_levels.${education.educationLevel}`) }}
                    </div>
                  </template>
                </v-card-text>

                <v-card-text v-if="application.reference">
                  <div class="subtitle-1">{{ $t('application.references') }}</div>
                  <p :style="{ whiteSpace: 'pre-line'}">{{ application.reference }}</p>
                </v-card-text>

                <v-card-text v-if="application.additionalInformation">
                  <div class="subtitle-1">{{ $t('application.additional_information') }}</div>
                  <p :style="{ whiteSpace: 'pre-line'}">{{ application.additionalInformation }}</p>
                </v-card-text>
              </v-tab-item>

              <!-- CV -->
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">{{ $t('application.cv') }}</div>
                    <applicant-cv
                      :applicant="applicant"
                      :application="application"
                      type="CV"
                      @updated="reloadFiles"
                      :files="cvFiles"
                      :show-add="true"
                      :noFilesMessage="this.$t('applicants.no_cv_help')"/>
                  </v-card-text>
                </v-card>
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">{{ $t('application.files') }}</div>
                    <applicant-cv
                      :applicant="applicant"
                      :application="application"
                      type="ADDITIONAL_FILE"
                      @updated="reloadFiles"
                      :files="additionalFiles"
                      :show-add="true"
                      :noFilesMessage="this.$t('applicants.no_files_help')"/>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- kyssärivastaukset -->
              <v-tab-item>
                <v-card-text>

                  <template v-for="(qa) in questionAnswers">
                    <v-card :key="qa.question.id">
                      <v-card-text>
                        <div class="body-1 font-weight-bold mb-2">{{qa.question.questionText}}</div>
                        <div v-if="['SELECT_ONE', 'SELECT_MANY'].includes(qa.question.type)">
                          <template v-for="ans in qa.answers">
                            <v-chip :key="ans.text" class="mr-2" color="primary" :outlined="!ans.selected">
                              <v-icon v-if="ans.selected">mdi-check</v-icon>
                              {{ ans.text }}
                            </v-chip>
                          </template>
                        </div>
                        <div v-else-if="qa.question.type === 'FREE_TEXT'">
                          <div class="body-1">{{qa.answers[0].text}}</div>
                        </div>
                        <div v-else-if="qa.question.type === 'SCALE'">
                          <v-slider ticks readonly track-fill-color="primary" color="primary" thumb-label="always" :value="qa.answers.find(a => a.selected).text" :max="qa.answers[qa.answers.length -1].text" :min="qa.answers[0].text" />
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-card-text>
              </v-tab-item>

              <!-- Viestit -->
              <v-tab-item v-if="false">
                <v-card-text>
                  <!-- TODO -->
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>

        <!-- Oikea paneeli -->
        <v-col cols="12" md="6" xl="6">
          <v-card :class="$vuetify.breakpoint.mdAndUp ? 'mt-3 ml-3' : 'mt-6'">
            <v-tabs grow show-arrows v-model="rightTabs">
              <v-tab>{{ $t('application.internal_chat')}}</v-tab>
              <v-tab>{{ $t('application.interview')}}</v-tab>

              <!--              Sisäinen keskustelu-->
              <v-tab-item eager>
                <internal-discussion
                  ref="discussion"
                  :application-id="applicationId"
                  @chat-loading="handleChatLoading"
                  :users="tenantUsers"
                />
              </v-tab-item>

              <!--              Haastattelu-->
              <v-tab-item>
                <v-card-text>
                  <v-textarea rows="7" v-model="personInterview" outlined :label="$t('applicants.person_interview')"></v-textarea>
                  <v-textarea rows="7" v-model="interviewNotes" outlined :label="$t('applicants.interview_comment')"></v-textarea>
                  <v-textarea rows="7" v-model="notes" outlined :label="$t('application.evaluation')"></v-textarea>
                  <v-btn class="primary mb-4" :loading="subtleLoading" small block @click="updateNotes">
                    <v-icon left>mdi-content-save</v-icon>
                    {{ $t('save') }}
                  </v-btn>
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <confirm-dialog ref="confirmDialog" />
    <job-application-status-change-dialog
      ref="statusChangeDialog"
      :applicationResults="applicationResults" />

  </hb-basic-page>
</template>
<script>
import tenantApi from "@/api/tenant";
import systemApi from "@/api/system";
import InternalDiscussion from "@/components/Tenant/InternalDiscussion.vue";
import ApplicantCv from "@/components/ApplicantCv.vue";
import JobApplicationStatusChangeDialog from '@/components/Tenant/SingleJobApplication/JobApplicationStatusChangeDialog';
import ConfirmDialog from '@/components/ConfirmDialog';

export default {
  name: "SingleJobApplicationView",
  components: { ConfirmDialog, JobApplicationStatusChangeDialog, ApplicantCv, InternalDiscussion},
  props: ['applicationId'],
  data() {
    return {
      generatingReport: false,
      loading: true,
      subtleLoading: false,
      leftTabs: 0,
      rightTabs: 0,
      application: {},
      questionAnswers: [],
      job:  {},
      applicationStatuses: [],
      applicationResults: [],
      currentApplicationStatus: null,
      currentApplicationResult: null,
      notes: '',
      interviewNotes: '',
      personInterview: '',
      files: [],
      tenantUsers: [],
    }
  },
  methods: {
    async reloadTenantUsers() {
      this.loading = true;
      this.tenantUsers = await tenantApi.loadTenantUsers();
      this.loading = false;
    },
    viewApplicantProfile() {
      this.$router.push({name: 'single_applicant', params: {id: this.applicant.id}});
    },
    async setApplicationStatus(status) {
      const [confirm, comment, result] = await this.$refs.statusChangeDialog.open(
        this.$t('application.confirm_status_change'),
        this.$t('application.confirm_status_change_help'),
        {
          color: 'primary',
          agree: this.$t('application.confirm_status_change_confirm'),
          cancel: this.$t('cancel'),
          comment: true,
          showResult: status === 'HANDLED',
        });

      const oldStatus = this.application.status;
      const oldResult = this.application.result;

      if (!confirm) {
        this.currentApplicationStatus = oldStatus;
        this.currentApplicationResult = oldResult;
        return;
      }

      this.subtleLoading = true;
      try {
        this.application = await tenantApi.updateApplication(this.applicationId, {
          status,
          result,
          comment,
        });
        this.currentApplicationStatus = this.application.status;
        this.currentApplicationResult = this.application.result;
        this.$nextTick(() => {
          this.$refs.discussion.fetchComments();
        })
      } catch (error) {
        this.$handleApiError(error);
        this.$nextTick(() => {
          this.currentApplicationStatus = oldStatus;
          this.currentApplicationResult = oldResult;
        })
      }
      this.subtleLoading = false;
    },
    async setApplicationResult(result) {
      const confirm = await this.$refs.confirmDialog.open(
        this.$t('application.confirm_result_change'),
        this.$t('application.confirm_result_change_help'),
        {
          color: 'primary',
          agree: this.$t('application.confirm_result_change_confirm'),
          cancel: this.$t('cancel'),
          comment: true,
        });

      const oldResult = this.application.result;

      if (!confirm) {
        this.currentApplicationResult = oldResult;
        return;
      }

      this.subtleLoading = true;
      try {
        this.application = await tenantApi.updateApplication(this.applicationId, {
          result,
          comment: confirm
        });
        this.$nextTick(() => {
          this.$refs.discussion.fetchComments();
        })
      } catch (error) {
        this.$handleApiError(error);
        this.$nextTick(() => {
          this.currentApplicationResult = oldResult;
        })
      }
      this.subtleLoading = false;
    },
    async updateNotes() {
      this.subtleLoading = true;
      try {
        this.application = await tenantApi.updateApplication(this.applicationId, {interviewNotes: this.interviewNotes, internalNotes: this.notes });
        if (this.application && this.application.applicant) {
          const applicant = {...this.application.applicant};
          applicant.personInterview = this.personInterview;
          applicant.education = applicant.education.map(e => {return {educationLevel: e.educationLevel, industry: e.industry}});
          applicant.experience = applicant.experience.map(e => {return {industryCode: e.industry.code, years: e.years}});
          applicant.licenses = applicant.licenses.map(l => {return {code: l.license.code}});
          await this.$tenantApi.updateApplicant(applicant.id, applicant);
        }
      } catch (error) {
        this.$handleApiError(error);
      }
      this.subtleLoading = false;
    },
    updateState() {
      this.currentApplicationStatus = this.application?.status;
      this.currentApplicationResult = this.application?.result;
      this.notes = this.application?.internalNotes;
      this.interviewNotes = this.application?.interviewNotes;
      this.personInterview = this.application?.applicant?.personInterview;
    },
    async generateReport() {
      try {
        this.generatingReport = true;
        const a = await this.$tenantApi.generateReport(this.application.id);
        const blob = new Blob([a], { type: a.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Työntekijäkortti_${this.application.applicant.firstName}_${this.application.applicant.lastName}`;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        this.$handleApiError(error);
      }
      this.generatingReport = false;
    },
    async reloadJobApplication() {
      this.loading = true;
      try {
        const response = await tenantApi.getApplication(this.applicationId);
        this.application = response?.application;
        this.questionAnswers = response?.questionAnswers;
        this.job = response?.application?.jobAd;
        await this.reloadFiles();
        this.updateState();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.loading = false;
    },
    async reloadFiles() {
      try {
        this.files = await tenantApi.getFilesByApplication(this.applicationId);
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    handleChatLoading(loading) {
      this.subtleLoading = loading;
    }
  },
  computed: {
    cvFiles() {
      return this.files.filter(f => f.type === 'CV');
    },
    additionalFiles() {
      return this.files.filter(f => f.type !== 'CV');
    },
    applicant() {
      return this.application ? this.application.applicant : {};
    },
    applicantLicenses() {
      if (!this.applicant && !this.applicant.licenses) {
        return [];
      }
      return this.applicant?.licenses?.filter(license => license.license.category === 'LICENSE');
    },
    jobLicenses() {
      return this.job?.licenses?.filter(l => l.category === 'LICENSE');
    },
    jobDrivingLicenses() {
      return this.job?.licenses?.filter(l => l.category === 'DRIVING');
    }
  },
  async mounted() {
    await this.reloadJobApplication();
    await this.reloadTenantUsers();
    this.$nextTick(() => {
      if (this.$refs.discussion) {
        this.$refs.discussion.fetchComments();
      }
    });
  },
  async created() {
    try {
      this.applicationStatuses = await systemApi.getApplicationStatuses();
      this.applicationResults = await systemApi.getApplicationResults();
    } catch (error) {
      this.$handleApiError(error);
    }
  }
}
</script>
