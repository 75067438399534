<template>
  <hb-basic-page :title="$tc('online_test_management.title')"
                 :loading="loading"
                 :search-title="`${$t('online_test_management.search')}...`"
                 @handle-search="handleSearch"
                 :fill-height="loading || (questions && questions.length === 0)"
                 name="online-test-management-view">

    <template v-if="!loading && questions && questions.length > 0">
      <v-col cols="12" class="pt-0 flex-shrink-1" :style="{height: $vuetify.breakpoint.smAndDown ? '0' : '60px'}">
        <v-fab-transition>
          <v-btn
            @click="addNewQuestion"
            color="primary"
            dark
            v-if="ready"
            :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
            :fab="$vuetify.breakpoint.smAndDown"
            :fixed="$vuetify.breakpoint.smAndDown"
            :bottom="$vuetify.breakpoint.smAndDown"
            :right="$vuetify.breakpoint.smAndDown"
            :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
          >
            <v-icon :left="$vuetify.breakpoint.mdAndUp">add</v-icon>
            <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('online_test_management.add_new_question') }}</span>
          </v-btn>
        </v-fab-transition>


        <!-- Tähän uusi nappi -->
        <v-fab-transition>
          <v-btn
            @click="clearLocalStorage"
            color=""
            dark
            v-if="ready"
            :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
            :fab="$vuetify.breakpoint.smAndDown"
            :fixed="$vuetify.breakpoint.smAndDown"
            :bottom="$vuetify.breakpoint.smAndDown"
            :left="$vuetify.breakpoint.smAndDown"
            :class="{'ma-0 ml-3': $vuetify.breakpoint.smAndUp}"
          >
            <v-icon :left="$vuetify.breakpoint.mdAndUp">delete</v-icon>
            <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('online_test_management.clearLocalStorage') }}</span>
          </v-btn>
        </v-fab-transition>

      </v-col>

      <v-col cols="12" md="8" offset-md="2" xl="6" offset-xl="3">
        <v-alert text dense type="info">
          {{ $t('online_test_management.help_text') }}
        </v-alert>
      </v-col>

      <v-col cols="12" md="8" offset-md="2" xl="6" offset-xl="3">
        <draggable v-model="questions" @end="questionDragEnd" :move="questionDragMove" handle=".handle">
          <template v-for="question in filteredQuestions">
            <QuestionCard :class="questionSearchPhrase ? '' : 'handle'"
                          :question="question"
                          :key="question.id"
                          :online-test="true"
                          :hide-details="hideQuestionDetails"
                          :selected-answers="selectedAnswers"
                          @handle-answer-select="handleAnswerSelect"
                          @handle-edit="handleQuestionEdit"
                          @handle-delete="handleDeleteQuestion"
                          :delete-text="$t('online_test_management.remove_question')" />
          </template>
        </draggable>
      </v-col>
    </template>

    <v-col cols="12" v-if="!loading && questions && questions.length === 0">
      <NoContentView :title="$t('questions_management.no_questions')" :description="$t('questions_management.no_questions_help')" icon="mdi mdi-magnify-scan" />
      <div class="text-center" style="margin-top: -50px; padding-bottom: 50px;">
        <v-fab-transition>
          <v-btn
            @click="addNewQuestion"
            color="primary"
            dark
            v-if="ready"
            :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('questions_management.add_new_question') }}</span>
          </v-btn>
        </v-fab-transition>
      </div>
    </v-col>

    <EditQuestionDialog ref="editQuestionDialog"
                        @question-updated="questionUpdated"
                        @question-created="questionCreated"
                        :online-test="true"/>

    <AnswerTestSnackbar :selected-answers="selectedAnswers" :questions="questions" @handle-reset="clearSelectedAnswers" />

  </hb-basic-page>
</template>

<script>
  import NoContentView from '@/components/NoContentView';
  import QuestionCard from '@/components/Tenant/QuestionCard';
  import EditQuestionDialog from '@/components/Tenant/EditQuestionDialog';
  import tenantApi from '@/api/tenant';
  import draggable from 'vuedraggable';
  import AnswerTestSnackbar from "../../components/Tenant/AnswerTestSnackbar";

  export default {
    name: 'OnlineTestManagementView',
    components: {AnswerTestSnackbar, NoContentView, QuestionCard, EditQuestionDialog, draggable},
    data() {
      return {
        ready: this.$vuetify.breakpoint.smAndUp,
        loading: true,
        subtleLoading: false,
        hideQuestionDetails: false,
        questions: [],
        questionSearchPhrase: '',
        selectedAnswers: [],
      }
    },
    methods: {
      handleAnswerSelect(answer) {
        const answerIndex = this.selectedAnswers.findIndex(a => a.id === answer.id);
        if (answerIndex > -1) {
          this.selectedAnswers.splice(answerIndex, 1);
        } else {
          this.selectedAnswers.push(answer);
        }
      },
      clearSelectedAnswers() {
        this.selectedAnswers = [];
      },
      questionCreated(question) {
        this.questions.push(question);
        this.$showSuccessNotification(this.$t('questions_management.question_created'))
      },
      questionUpdated(question) {
        const index = this.questions.findIndex(q => q.id === question.id);
        this.questions.splice(index, 1, question);
        this.$showSuccessNotification(this.$t('questions_management.question_updated'));
      },
      async handleDeleteQuestion(question) {
        try {
          await tenantApi.removeQuestionFromOnlineTest(question.id);
          this.questions = this.questions.filter(q => q.id !== question.id);
          this.$showSuccessNotification(this.$t('online_test_management.question_removed'));
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      handleQuestionEdit(question) {
        this.$refs.editQuestionDialog.open(question);
      },
      handleSearch(searchPhrase) {
        this.questionSearchPhrase = searchPhrase;
      },
      addNewQuestion() {
        this.$refs.editQuestionDialog.open();
      },
      async reloadData() {
        this.loading = true;
        try {
          this.questions = await tenantApi.getOnlineTestQuestions();
        } catch (error) {
          this.$handleApiError(error);
        }
        this.loading = false;
      },
      async questionDragEnd() {
        this.subtleLoading = true;
        try {
          await tenantApi.reorderOnlineTestQuestions(this.questions.map(q => q.id));
        } catch (error) {
          this.$handleApiError(error);
        }
        this.subtleLoading = false;
      },
      questionDragMove() {
        // disable reordering when list is filtered
        return !this.questionSearchPhrase;
      },
      clearLocalStorage() {
        localStorage.removeItem(`online_test_${this.$tenant}`)
      }
    },
    computed: {
      filteredQuestions() {
        const searchPhrase = this.questionSearchPhrase.toLowerCase();
        return this.questions.filter(q =>
          q.questionText.toLowerCase().includes(searchPhrase) ||
          q.answers.flatMap(a => a.tags).some(t => t.toLowerCase().startsWith(searchPhrase)) ||
          q.requiredTags.some(t => t.description.toLowerCase().startsWith(searchPhrase)) ||
          q.exclusiveTags.some(t => t.description.toLowerCase().startsWith(searchPhrase)) ||
          q.classificationTags.some(t => t.description.toLowerCase().startsWith(searchPhrase))
        );
      },
    },
    mounted() {
      this.reloadData();
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.ready = true
        }, 300);
      } else {
        this.ready = true;
      }
    }
  }
</script>

<style scoped>

</style>
