<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card :tile="$vuetify.breakpoint.xsOnly">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('questions_management.add_new_question') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-tabs v-model="tabs" background-color="primary darken-1" dark color="primary" slider-color="accent">
        <v-tab>{{ $t('job_ads.choose_from_list') }}</v-tab>
        <v-tab>{{ $t('create_new') }}</v-tab>
      </v-tabs>

      <div class="scroll-area">

        <v-card-text class="px-4 pb-14">

          <v-alert type="info" :value="true" text>
            {{ $t('job_ads.link_question_help') }}
          </v-alert>

          <v-tabs-items v-model="tabs">
            <v-tab-item class="pt-2">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete :label="$t('questions_management.filter_by_classification_tag')"
                              v-model="selectedClassificationTag"
                              :items="allClassificationTags"
                              item-text="description"
                              dense
                              outlined
                              chips
                              small-chips
                              deletable-chips
                              multiple
                              clearable
                              hide-details />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="questionSearchPhrase"
                                :label="`${$t('questions_management.search')}...`"
                                dense
                                hide-details
                                outlined>
                    <v-icon style="cursor: pointer;" slot="append">search</v-icon>
                  </v-text-field>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12">
                  <v-switch hide-details
                            style="margin-top: 0; padding-top:0"
                            :label="$t('questions_management.show_only_test_questions')"
                            v-model="filterTest"
                  />
                </v-col>
              </v-row>


              <template v-if="filteredQuestions.length > 0">
                <v-list-item v-if="!areAllQuestionsSelected" @click="selectAllQuestions" class="mt-2">
                  <v-list-item-icon>
                    <v-icon v-if="areSomeOfTheQuestionsSelected" >mdi mdi-minus-circle-outline</v-icon>
                    <v-icon v-else >mdi mdi-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-break text-wrap chooseAll_button_text">
                      {{ $t('questions_management.selectAllQuestions') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="areAllQuestionsSelected" @click="unSelectAllQuestions" class="mt-2">
                  <v-list-item-icon>
                    <v-icon color="secondary" >mdi mdi-check-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-break text-wrap chooseAll_button_text">
                      {{ $t('questions_management.unSelectAllQuestions') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-row v-if="questionsLoading" class="mt-16">
                <v-col cols="12" class="text-center">
                  <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
                </v-col>
              </v-row>
              <v-list v-else :dense="$vuetify.breakpoint.xsOnly" class="mt-1">
                <template v-for="question in filteredQuestions">
                  <v-list-item :key="question.id" @click="selectQuestion(question)">
                    <v-list-item-icon>
                      <v-icon color="primary" v-if="question.selected">mdi mdi-check-circle-outline</v-icon>
                      <v-icon v-if="!question.selected">mdi mdi-circle-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-break text-wrap">
                        {{ question.questionText }}
                        <v-chip small color="success" text-color="white" v-if="question.quiz">
                          {{ $t('questions_management.online_test') }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t('questions_management.classification_tags') }}:
                        <template v-if="question.classificationTags && question.classificationTags.length > 0">
                          <template v-for="tag in question.classificationTags">
                            <v-chip :key="tag.description" x-small class="mr-1" color="default">{{ tag.description }}</v-chip>
                          </template>
                        </template>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ $t('questions_management.answer_tag_combos') }}:
                        <template v-if="question.answers.length > 0">
                          <template v-for="answer in question.answers">
                            <v-chip :key="`answer_${answer.id}`" x-small class="mr-1" color="accent-2" text-color="primary">
                              <strong>{{ answer.answerText }}</strong>{{ answer.tags.length > 0 ? '- ' + answer.tags.join(', ') : '' }}
                            </v-chip>
                          </template>
                        </template>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ $t('job_ads.required_tags') }}:
                        <template v-if="question.requiredTags && question.requiredTags.length > 0">
                          <template v-for="tag in question.requiredTags">
                            <v-chip :key="tag.description" x-small class="mr-1" color="blue" text-color="white">{{ tag.description }}</v-chip>
                          </template>
                        </template>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ $t('job_ads.exclusive_tags') }}:
                        <template v-if="question.exclusiveTags && question.exclusiveTags.length > 0">
                          <template v-for="tag in question.exclusiveTags">
                            <v-chip :key="tag.description" x-small class="mr-1" color="red" text-color="white">{{ tag.description }}</v-chip>
                          </template>
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-tab-item>
            <v-tab-item>

              <EditQuestionForm ref="newQuestionForm" :job="job" :is-new="true" />

            </v-tab-item>
          </v-tabs-items>

          <v-alert :value="showAlert" outlined class="mt-4" type="error">Tarkista, että olet täyttänyt kaikki pakolliset kentät.</v-alert>

        </v-card-text>

      </div>

      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-switch hide-details
                  style="margin-top: 0; padding-top:0"
                  :label="$t('questions_management.show_only_test_questions')"
                  v-model="filterTest"
        />
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :disabled="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               v-if="tabs === 0"
               @click="saveSelections"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('job_ads.add_selections') }}</span>
        </v-btn>
        <v-btn color="primary"
               v-if="tabs === 1"
               @click="saveQuestion"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('job_ads.save_and_add_question') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EditQuestionForm from "@/components/Tenant/EditQuestionForm";
import tenantApi from '@/api/tenant';

export default {
  name: 'AddQuestionsDialog',
  components: {EditQuestionForm},
  props: ['job'],
  data() {
    return {
      loading: false,
      questionsLoading: false,
      tabs: null,
      dialog: false,
      showAlert: false,
      questionSearchPhrase: '',
      questions: [],
      filterTest: true,
      selectedClassificationTag: null,
    }
  },
  methods: {
    open() {
      this.dialog = true;
      this.reloadData();
    },
    async reloadData() {
      this.questionsLoading = true;
      try {
        this.questions = await tenantApi.getQuestions();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.questionsLoading = false;
    },
    async saveSelections() {
      this.loading = true;
      let selectedCount = 0;
      try {
        for (const q of this.questions) {
          if (q.selected) {
            selectedCount++;
            await tenantApi.addQuestionToJob(q.id, this.job.id);
          }
        }
      } catch (error) {
        this.$handleApiError(error);
      }
      if (selectedCount > 0) {
        await this.reloadData();
        this.$emit('questions-changed');
      }

      this.loading = false;
      this.dialog = false;
    },
    async saveQuestion() {
      this.loading = true;
      try {
        await this.$refs.newQuestionForm.handleSave();
        this.$emit('questions-changed');
        this.dialog = false;
      } catch (error) {
        this.$handleApiError(error);
      }

      this.loading = false;
    },
    selectQuestion(question) {
      this.$set(question, 'selected', !question.selected);
    },
    questionTags(question) {
      let tags = [];
      if (question.answers && question.answers.length > 0) {
        question.answers.forEach(answer => {
          if (answer.tags && answer.tags.length > 0) {
            answer.tags.forEach(tag => {
              if (!tags.includes(tag)) {
                tags.push(tag);
              }
            });
          }
        });
      }
      return tags;
    },
    selectAllQuestions() {
      this.filteredQuestions.forEach(question => {
          this.$set(question, 'selected', true);

      })
    },
    unSelectAllQuestions() {
      this.questions.forEach(question => {
        this.$set(question, 'selected', false);

      })
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.showAlert = false;
        this.questionSearchPhrase = '';
        this.newQuestion = {
          title: '',
          type: null,
          quiz: false,
          answers: [
            {title: '', tags: []},
          ],
        }
        this.$emit('on-dismiss');
      }
    },
  },
  computed: {
    allClassificationTags() {
      const tags = [];
      this.questions.forEach(q => {
        q.classificationTags.forEach(ct => {
          if (tags.findIndex(t => t.id === ct.id) === -1) {
            tags.push(ct);
          }
        });
      });
      return tags;
    },
    filteredQuestions() {
      const qs = this.job.questions || [];
      let questions = this.questions;

      if (this.filterTest) {
        questions = questions.filter(q => q.quiz);
      }
      const jobAdQuestionIds = qs.map(q => q.id);
      const searchPhrases = this.questionSearchPhrase.toLowerCase().split(' ');
      return questions.filter(q => {
        const found = [];
        found.push(!jobAdQuestionIds.includes(q.id));
        searchPhrases.forEach(searchPhrase => {
          found.push((
            q.questionText.toLowerCase().includes(searchPhrase) ||
            q.answers.flatMap(a => a.tags).some(t => t.toLowerCase().startsWith(searchPhrase)) ||
            q.requiredTags.some(t => t.description.toLowerCase().startsWith(searchPhrase)) ||
            q.classificationTags.some(t => t.description.toLowerCase().startsWith(searchPhrase))
          ) && (
            !this.selectedClassificationTag || this.selectedClassificationTag.length === 0 ||
            this.selectedClassificationTag.every(sct => q.classificationTags.some(ct => ct.description === sct))
          ));
        })
        return found.indexOf(false) === -1;
      });
    },
    areAllQuestionsSelected() {
      return this.filteredQuestions.every(q => q.selected);
    },
    areSomeOfTheQuestionsSelected() {
      return this.filteredQuestions.some(q => q.selected) && this.filteredQuestions.some(q => !q.selected);
    }
  },
  mounted() {
    // this.reloadData();
  }
}
</script>

<style lang="scss" scoped>
.scroll-area {
  max-height: calc(100vh - 104px);
  overflow: auto;
}

@media only screen and (min-width: 600px) {
  .scroll-area {
    height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
  }
}
</style>

<style scoped>
  .chooseAll_button_text {
    color: grey;
  }
</style>
