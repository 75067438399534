<template>
  <hb-basic-page :search-title="`${$t('tenants.search')}...`"
              :subtle-loading="subtleLoading"
              :loading="loading"
              name="tenants-list"
              @handle-search="handleSearch"
              :noContainerPadding="$vuetify.breakpoint.xsOnly"
              no-gutters>

    <v-col cols="12" sm="8" lg="9" xl10 :style="{height: $vuetify.breakpoint.xsOnly ? '0' : 'auto'}">
      <add-tenant-dialog @on-dismiss="onAddTenantDialogDismiss" />
    </v-col>
    <v-col cols="12" v-if="filteredTenants.length > 0" :pb-5="$vuetify.breakpoint.xsOnly">
      <v-list two-line color="transparent" class="pa-0 pb-15">
        <v-list-item class="tenants-header" v-if="$vuetify.breakpoint.smAndUp">
          <v-list-item-avatar></v-list-item-avatar>
          <v-list-item-content class="grey--text">
            <v-container fluid ma-0 pa-0>
              <v-row align="center" justify="center" class="fill-height" ma-0>
                <v-col cols="12" sm="6" lg="5" xl="3">
                  <span class="caption">{{ $t('tenants.organization_name') }}</span>
                </v-col>
                <v-col cols="12" sm="6" lg="7" xl="9">
                  <span class="caption">{{ $t('tenants.status') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action style="width: 36px;"></v-list-item-action>
        </v-list-item>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

        <template v-for="tenant in filteredTenants">
          <v-list-item
            style="background: none;"
            :key="`${tenant.id}_item`"
          >
            <v-list-item-avatar :color="isTenantEnabled(tenant) ? $randomizeColorFromString(tenant.id, 75, 70) : 'grey lighten-2'" class="organization-avatar">
              <span class="title text-uppercase font-weight-black white--text">{{ tenant.name.substring(0, 2) }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-container fluid ma-0 pa-0>
                <v-row align="center" justify="center" class="fill-height" ma-0>
                  <v-col cols="12" sm="6" lg="5" xl="3" :class="!isTenantEnabled(tenant) ? 'grey--text text--lighten-1' : ''">
                    {{ tenant.name }}
                  </v-col>
                  <v-col v-if="$vuetify.breakpoint.smAndUp" sm="6" lg="7" xl="9">
                    {{ $t('tenants.statuses.' + tenant.status) }}
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu bottom left :close-on-content-click="$vuetify.breakpoint.smAndUp">
                <template v-slot:activator="{ on }">
                  <v-btn text icon class="mt-0 mb-0" color="grey"><v-icon size="28" v-on="on">mdi mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list dense class="pt-0 pb-0">
                  <template v-if="$vuetify.breakpoint.xsOnly">
                    <v-subheader>{{ $t('tenants.organization_name') }}:</v-subheader>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon size="18">fas fa-users</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">{{ tenant.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-subheader>{{ $t('tenants.manage_tenant') }}:</v-subheader>
                  <v-list-item @click="openTenantUsersManagement(tenant)">
                    <v-list-item-avatar><v-icon>people</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ $t('tenants.users') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="openTenantDetails(tenant)">
                    <v-list-item-avatar><v-icon>settings</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ $t('tenants.settings') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="isTenantEnabled(tenant)" @click="toggleTenantState(tenant)">
                    <v-list-item-avatar><v-icon size="18">fas fa-ban</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ $t('tenants.disable') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!isTenantEnabled(tenant)" @click="toggleTenantState(tenant)">
                    <v-list-item-avatar><v-icon size="18">fas fa-check-circle</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ $t('tenants.enable') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`${tenant.id}_divider`"></v-divider>
        </template>

      </v-list>
    </v-col>

    <tenant-details-dialog ref="tenantDetailsDialog" @on-dismiss="onTenantDetailsDialogDismiss" />
    <users-dialog ref="usersDialog" />

  </hb-basic-page>
</template>

<script>
  import AddTenantDialog from "../components/Tenants/AddTenantDialog";
  import TenantDetailsDialog from "../components/Tenants/TenantDetailsDialog";
  import UsersDialog from "../components/Tenants/UsersDialog";
  import adminApi from "../api/admin";

  export default {
    name: 'TenantView',
    components: {
      AddTenantDialog: AddTenantDialog,
      TenantDetailsDialog: TenantDetailsDialog,
      UsersDialog: UsersDialog,
    },
    data() {
      return {
        tenants: [],
        subtleLoading: false,
        loading: true,
        searchPhrase: '',
      }
    },
    methods: {
      handleSearch(searchPhrase) {
        this.searchPhrase = searchPhrase;
      },
      isTenantEnabled(tenant) {
        return tenant.status !== 'inactive';
      },
      async loadTenants() {
        try {
          const tenants = await this.$adminApi.getTenants();
          this.tenants = tenants;
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async onAddTenantDialogDismiss() {
        this.subtleLoading = true;
        await this.loadTenants();
        this.subtleLoading = false;
      },
      async onTenantDetailsDialogDismiss() {
        this.subtleLoading = true;
        await this.loadTenants();
        this.subtleLoading = false;
      },
      openTenantDetails(tenant) {
        this.$refs.tenantDetailsDialog.open(tenant);
      },
      async toggleTenantState(tenant) {
        const status = this.isTenantEnabled(tenant) ? 'INACTIVE' : 'ACTIVE';
        const data = {
          name: tenant.name,
          status,
        };
        try {
          const updatedTenant = await adminApi.updateTenant(tenant.id, data);
          const tenantIndex = this.tenants.findIndex(t => t.id === updatedTenant.id);
          this.tenants.splice(tenantIndex, 1, updatedTenant);

        } catch (error) {
          this.$handleApiError(error);
        }
      },
      openTenantUsersManagement(tenant) {
        this.$refs.usersDialog.open(tenant);
      },
    },
    computed: {
      filteredTenants() {
        const filteredTenants = this.tenants;

        if (this.searchPhrase.length === 0) return filteredTenants;

        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");
        return filteredTenants.filter(tenant => {
          let found = [];
          const lowerSearchedString =
            (tenant.name ? tenant.name.toLowerCase() : '');
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          /*
          if (filterOutActive && tenant.enabled) found.push(false);
          if (filterOutInactive && !tenant.enabled) found.push(false);
           */
          return found.indexOf(false) === -1;
        })
      },
    },
    async mounted() {
      this.loading = true;
      await this.loadTenants();
      this.loading = false;
    }
  }
</script>

<style lang="scss" scoped>
.organization-avatar {
  span {
    display: inline-block;
    width: 100%;
  }
}
</style>
