<template>
<v-card :elevation="excludedQuestionBasedOnSelectedAnswers || !includedQuestionBasedOnSelectedAnswers ? 0 : 2"
        class="mb-4"
        color="grey lighten-4"
        outlined
        :class="cardClass">
  <v-card-title class="text-break subtitle-1 pb-0" >
    <div>
      <div>
        <v-tooltip top v-if="excludedQuestionBasedOnSelectedAnswers">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="18" style="margin-top: -3px;" color="error">fa fa-eye-slash</v-icon>
          </template>
          <span>{{ $t('questions_management.question_isnt_shown_exclusive_tags') }}: <strong>{{ question.exclusiveTags.map(t => t.description).join(', ') }}</strong></span>
        </v-tooltip>
        <v-tooltip top v-if="!includedQuestionBasedOnSelectedAnswers">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="18" style="margin-top: -3px;" color="error">fa fa-eye-slash</v-icon>
          </template>
          <span>{{ $t('questions_management.question_isnt_shown_required_tags') }}: <strong>{{ question.requiredTags.map(t => t.description).join(', ') }}</strong></span>
        </v-tooltip>
        <v-tooltip top v-if="includedQuestionBasedOnSelectedAnswers && question.requiredTags.length > 0 && selectedAnswers.length > 0">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="18" style="margin-top: -3px;" color="success">fa fa-eye</v-icon>
          </template>
          <span>{{ $t('questions_management.question_is_shown_because_required_tags') }}: <strong>{{ question.requiredTags.map(t => t.description).join(', ') }}</strong></span>
        </v-tooltip>
        {{ question.questionText }}
      </div>
    </div>
    <template v-if="showAnswerCount">
      <v-spacer></v-spacer>
      <div class="text-body-2 secondary--text">
        {{ $t('questions_management.answered_count') }}: <strong>{{ question.answeredCount }}</strong>
      </div>
    </template>
  </v-card-title>
  <v-card-text class="py-0" v-if="!hideDetails">
    <div class="mt-3 mt-sm-1">
      {{ $t('questions_management.linked_jobs') }}:
      <br v-if="$vuetify.breakpoint.xsOnly" />
      <template v-for="linkedJob in question.jobAds">
        <v-chip :key="linkedJob.id" x-small class="mr-1" color="accent" text-color="primary">{{ linkedJob.name }}<template v-if="linkedJob.client">{{ linkedJob.client }}</template></v-chip>
      </template>
    </div>
    <div class="mt-3 mt-sm-1" v-if="question.classificationTags.length > 0">
      {{ $t('questions_management.classification_tags') }}:
      <br v-if="$vuetify.breakpoint.xsOnly" />
      <template v-for="tag in question.classificationTags">
        <v-chip :key="`classification_tag_${tag.id}`" x-small class="mr-1" color="default">{{ tag.description }}</v-chip>
      </template>
    </div>
    <div class="mt-3 mt-sm-1" v-if="question.answers.length > 0">
      {{ $t('questions_management.answer_tag_combos') }}:
      <br v-if="$vuetify.breakpoint.xsOnly" />
      <template v-for="answer in question.answers">
        <v-chip :disabled="answerDisabled(answer)"
                :key="`answer_${answer.id}`"
                x-small
                class="mr-1"
                :class="answerIsSelected(answer) ? 'elevation-3' : ''"
                :color="answerIsSelected(answer) ? 'black' : 'accent-2'"
                :text-color="answerIsSelected(answer) ? 'white' : 'primary'"
                @click="handleAnswerSelect(answer)">
          <strong>{{ answer.answerText }}</strong>{{ answer.tags.length > 0 ? '- ' + answer.tags.join(', ') : '' }}
        </v-chip>
      </template>
    </div>
    <div class="mt-3 mt-sm-1" v-if="question.requiredTags.length > 0">
      {{ $t('job_ads.required_tags') }}:
      <br v-if="$vuetify.breakpoint.xsOnly" />
      <template v-for="tag in question.requiredTags">
        <v-chip :key="`required_tag_${tag.id}`" x-small class="mr-1" color="blue" text-color="white">{{ tag.description }}</v-chip>
      </template>
    </div>
    <div class="mt-3 mt-sm-1" v-if="question.exclusiveTags.length > 0">
      {{ $t('job_ads.exclusive_tags') }}:
      <br v-if="$vuetify.breakpoint.xsOnly" />
      <template v-for="tag in question.exclusiveTags">
        <v-chip :key="`exclusive_tag_${tag.id}`" x-small class="mr-1" color="red" text-color="white">{{ tag.description }}</v-chip>
      </template>
    </div>
    <div v-if="!onlineTest" class="mt-3 mt-sm-1">
      {{ $t('questions_management.show_in_quiz') }}:
      <br v-if="$vuetify.breakpoint.xsOnly" />
      <v-chip v-if="question.quiz" color="success" x-small>{{ $t('yes') }}</v-chip>
      <v-chip v-else color="default" x-small>{{ $t('no') }}</v-chip>
    </div>
  </v-card-text>
  <v-card-actions>
    <v-btn color="primary" small text @click="handleEdit">{{ $t('edit') }}</v-btn>
    <v-btn color="default" small text @click="handleDelete">{{ deleteText }}</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
export default {
  name: "QuestionCard",
  props: ['question', 'hideDetails', 'deleteText', 'onlineTest', 'selectedAnswers', 'showAnswerCount'],
  methods: {
    handleEdit() {
      this.$emit('handle-edit', this.question);
    },
    handleDelete() {
      this.$emit('handle-delete', this.question);
    },
    handleAnswerSelect(answer) {
      const answerIndex = this.questionSelectedAnswers.findIndex(a => a.id === answer.id);
      if (answerIndex > -1) {
        this.questionSelectedAnswers.splice(answerIndex, 1);
      } else {
        this.questionSelectedAnswers.push(answer);
      }
      this.$emit('handle-answer-select', answer);
    },
    answerDisabled(answer) {
      if (this.excludedQuestionBasedOnSelectedAnswers || !this.includedQuestionBasedOnSelectedAnswers) return true;
      if (this.questionSelectedAnswers.length === 0) return false;
      if (this.question.type === 'SCALE' || this.question.type === 'SELECT_ONE') {
        return this.questionSelectedAnswers.findIndex(a => a.id === answer.id) === -1;
      }
      return false;
    },
    answerIsSelected(answer) {
      return this.questionSelectedAnswers.findIndex(a => a.id === answer.id) > -1;
    },
  },
  watch: {
    selectedAnswers(val) {
      if (val.length > 0) {
        if (this.questionSelectedAnswers.length > 0) {
          if (this.excludedQuestionBasedOnSelectedAnswers || !this.includedQuestionBasedOnSelectedAnswers) {
            this.questionSelectedAnswers.forEach(answer => this.handleAnswerSelect(answer));
          }
        }
      }
    }
  },
  computed: {
    cardClass() {
      if (this.selectedAnswers.length === 0) return '';
      if (this.excludedQuestionBasedOnSelectedAnswers || !this.includedQuestionBasedOnSelectedAnswers) return 'excluded-question';
      if (this.question.requiredTags.length > 0 && this.includedQuestionBasedOnSelectedAnswers) return 'included-question';
      return ''
    },
    questionSelectedAnswers() {
      return this.selectedAnswers.filter(answer => answer.questionId === this.question.id);
    },
    excludedQuestionBasedOnSelectedAnswers() {
      let selectedTags = [];
      this.selectedAnswers.forEach(a => selectedTags = selectedTags.concat(a.tags));
      if (selectedTags.length > 0 && this.question.exclusiveTags && this.question.exclusiveTags.length > 0) {
        return this.question.exclusiveTags.findIndex(etag => selectedTags.includes(etag.description)) > -1;
      }
      return false;
    },
    includedQuestionBasedOnSelectedAnswers() {
      let selectedTags = [];
      this.selectedAnswers.forEach(a => selectedTags = selectedTags.concat(a.tags));
      if (selectedTags.length > 0 && this.question.requiredTags && this.question.requiredTags.length > 0) {
        return this.question.requiredTags.findIndex(etag => selectedTags.includes(etag.description)) > -1;
      }
      return true;
    },
    tags() {
      let tags = [];
      if (this.question.answers) {
        this.question.answers.forEach(answer => {
          if (answer.tags && answer.tags.length > 0) {
            answer.tags.forEach(tag => {
              if (tags.findIndex(t => t === tag) === -1) {
                tags.push(tag);
              }
            });
          }
        });
      }
      return tags;
    }
  }
}
</script>

<style lang="scss" scoped>
.single-job-ad-view, .online-test-management-view {
  .excluded-question {
    opacity: 0.8;
    border-color: var(--v-error-lighten3) !important;
  }

  .included-question {
    border-color: var(--v-success-lighten3) !important;
  }
}
</style>
