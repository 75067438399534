<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card :tile="$vuetify.breakpoint.xsOnly">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('application.dialog_new_title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-form ref="form">
          <v-autocomplete
            v-model="application.applicantId"
            :items="applicants"
            :label="$t('application.applicant')"
            :item-text="(item) => `${item.firstName} ${item.lastName} (${item.email})`"
            item-value="id"
            :disabled="true"
            :rules="[$rules.required]"
          />
          <v-autocomplete
            v-model="application.jobAdId"
            :items="filteredJobAds"
            :label="$t('application.job_ad')"
            item-text="name"
            item-value="id"
            :rules="[$rules.required]"
          >
            <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.customers[0]">{{ item.customers[0].name }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="text-right">
                  <v-list-item-title>
                    <v-chip x-small class="white--text" :color="item.state === 4 ? 'blue' : 'green'">{{ $t('job_ads.states.' + item.state) }}</v-chip>
                  </v-list-item-title>
                </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-textarea :label="$t('application.references')" v-model="application.reference"/>
          <v-textarea :label="$t('application.additional_information')" v-model="application.additionalInformation"/>
        </v-form>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="close"
               :disabled="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="save"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment/moment";

export default {
  name: 'AddOrEditApplicationDialog',
  props: {
    jobAds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      application: {},
      applicant: { },
      applicants: [],
    };
  },
  computed: {
    filteredJobAds() {
      if (!this.applicant.id) {
        return this.jobAds;
      }
      return this.jobAds.filter(a => !this.applicant.appliedJobAds.some(applied => applied.jobAd.id === a.id) && moment(a.searchEndDate).isAfter(moment()));
    },
  },
  methods: {
    isJobAdActive(searchEndDate) {
      return moment(searchEndDate).isAfter(moment());
    },
    open(applicant, application) {
      this.applicant = applicant;
      this.application = application ? { ...application } : { applicantId: this.applicant.id };
      this.applicants = [ this.applicant ];
      this.dialog = true;
    },
    close() {
      this.$refs.form.resetValidation();
      this.application = {};
      this.dialog = false;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        await this.$tenantApi.createApplication(this.application);
        this.$emit('updated', this.application);
        this.close();
      } catch (e) {
        this.$handleApiError(e);
      }
    },
  },
}
</script>

<style scoped>

</style>
