<template>
  <v-list-item v-if="job" :to="link" class="py-2">
    <v-list-item-icon style="margin-right: 21px;">
      <div class="text-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="error-icon" color="error" v-on="on" :style="{display: job.missingDetails ? 'block' : 'none'}">mdi mdi-playlist-remove</v-icon>
        </template>
        <span>{{ $t('job_ads.missing_details') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="error-icon" color="error" v-on="on" :style="{display: job.missingQuestions ? 'block' : 'none'}">mdi mdi-help-circle-outline</v-icon>
        </template>
        <span>{{ $t('job_ads.missing_questions') }}</span>
      </v-tooltip>
      </div>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="title">
        <v-row no-gutters justify="space-between">
          <div>
            {{ job.name ? job.name : '-' }} <v-chip style="margin-bottom: 5px;" v-if="job.unread" x-small>{{ $t('job_ads.new') }}</v-chip>
          </div>
          <div v-if="$vuetify.breakpoint.smAndUp" class="caption grey--text text--darken-1">
            <strong>{{ $t('job_ads.opened') }}:</strong> {{ job.openCount }}
            <strong>{{ $t('job_ads.sent') }}:</strong> {{ job.submitCount }}
          </div>
        </v-row>
      </v-list-item-title>
      <v-list-item-subtitle>
        <strong>{{ $t('job_ads.search_time') }}:</strong> {{ $formatDateNoTime(job.searchStartDate) }} - {{ $formatDateNoTime(job.searchEndDate) }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <strong>{{ $t('job_ads.client') }}:</strong> {{ customers }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <strong>{{ $t('job_ads.state') }}:</strong> {{ state }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import TenantService from '@/service/tenant.service';

export default {
  name: 'JobListItem',
  props: {
    job: {
      type: Object,
    }
  },
  computed: {
    link() {
      return {name: 'job_ad', params: {tenantId: TenantService.tenant, id: this.job.id}};
    },
    customers() {
      return this.job.customers.length ? this.job.customers.map(c => c.name).join(', ') : '-';
    },
    state() {
      return this.$t('job_ads.states')[this.job.state];
    },
  }
}
</script>

<style scoped>

</style>
