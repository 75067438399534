<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card :tile="$vuetify.breakpoint.xsOnly">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('job_ads.additional_info') + ' - ' + job.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <JobDetailsForm
          :contact-persons="contactPersons"
          :model="model"
          :industries="industries"
        />
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="close"
               :disabled="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="save"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from '@/api/tenant';
import JobDetailsForm from "@/components/Tenant/SingleJobAd/JobDetailsForm";

export default {
  name: 'EditJobDetailsDialog',
  components: {JobDetailsForm},
  props: ['job'],
  data() {
    return {
      dialog: false,
      loading: false,
      model: {},
      contactPersons: [],
      industries: [],
    };
  },
  methods: {
    async open() {
      this.model = { ...this.job };
      this.dialog = true;
      this.loading = true;
      try {
        this.contactPersons = await tenantApi.loadTenantUsers();
        this.industries = (await tenantApi.getIndustries()).filter(i => i.code !== '_');
      } catch (err) {
        this.$handleApiError(err);
      }
      this.loading = false;
    },
    close() {
      this.dialog = false;
      this.model = {};
      this.$emit('on-dismiss');
    },
    async save() {
      this.loading = true;
      try {
        const jobAd = await tenantApi.updateJobAd(this.model.id, this.model);
        this.$emit('updated', jobAd);
        this.close();
      } catch (err) {
        this.$handleApiError(err);
      }
      this.loading = false;
    },
  },
}
</script>

