<script>
// git-blamen säästäjä: bugit tarjoaa Janne Rajuvaara
export default {
  name: "HbLicenceSelector",
  props: ['selectedItems'],
  data() {
    return {
      loading: false,
      kaikkiLuvat: [],
      ajokorttiSelect: [],
    }
  },
  computed: {
    eiValitutLupakirjat() {
      return this.lupakirjat.filter(licence =>
        !this.selectedItems.includes(licence.code)
      );
    },
    valitutLupakirjat() {
      return this.lupakirjat.filter(licence =>
        this.selectedItems.includes(licence.code)
      );
    },
    lupakirjat() {
      return this.kaikkiLuvat.filter(l => l.category === "LICENSE");
    },
    ajokorttiLuokat() {
      return this.kaikkiLuvat.filter(l => l.category === "DRIVING");
    },
    valitutAjokorttiLuokat() {
      return this.ajokorttiLuokat.filter(l => this.selectedItems.includes(l.code));
    },
    ajoLuvat() {
      return this.kaikkiLuvat.filter(l => l.category === "DRIVING_EXTRA");
    }
  },
  methods: {
    async reloadLicenses() {
      this.loading = true;
      try {
        this.kaikkiLuvat = await this.$tenantApi.getLicenses();
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loading = false;
    },
    toggleSelection(license) {
      let selectedItems = [...this.selectedItems];
      const index = selectedItems.findIndex(l => l === license.code);
      if (index === -1) {
        selectedItems.push(license.code);
      } else {
        selectedItems.splice(index, 1);
      }
      this.$emit('change', selectedItems);
    },
  },
  watch: {
    selectedItems(newVal) {
      const newSelected = this.ajokorttiLuokat.filter(l => newVal?.includes(l.code)).map(l => l.code);
      if (JSON.stringify(newSelected) !== JSON.stringify(this.ajokorttiSelect)) {
        this.ajokorttiSelect = newSelected;
      }
    },
    ajokorttiSelect(to, from) {
      if (to.length > from.length) {
        const added = to.filter(i => !from.includes(i));
        const newSelectedItems = [...this.selectedItems, added].flat();
        if (JSON.stringify(newSelectedItems) !== JSON.stringify(this.selectedItems)) {
          this.$emit('change', newSelectedItems);
        }
      } else if (to.length < from.length) {
        const removed = from.filter(i => !to.includes(i));
        const newSelectedItems = this.selectedItems.filter(i => !removed.includes(i));
        if (JSON.stringify(newSelectedItems) !== JSON.stringify(this.selectedItems)) {
          this.$emit('change', newSelectedItems);
        }
      }
    }
  },
  async mounted() {
    await this.reloadLicenses();
    this.ajokorttiSelect = this.ajokorttiLuokat.filter(l => this.selectedItems?.includes(l.code)).map(l => l.code);
  }
}
</script>

<template>
  <div style="container-type: inline-size;">
    <v-chip-group v-if="valitutLupakirjat && valitutLupakirjat.length" class="mt-0" column multiple>
      <v-chip
        v-for="license in valitutLupakirjat"
        :key="license.code"
        class="primary"
        @click="toggleSelection(license)"
      >
        {{ $t(`licenses.${license.code}`) }}
      </v-chip>
    </v-chip-group>
    <v-chip v-else disabled class="my-2 py-2">{{ $t('applicants.no_filtering') }}</v-chip>
    <v-divider v-if="eiValitutLupakirjat && eiValitutLupakirjat.length" ></v-divider>
    <v-chip-group column multiple>
      <v-chip
        v-for="license in eiValitutLupakirjat"
        :key="license.code"
        :code="license"
        @click="toggleSelection(license)"
      >
        {{ $t(`licenses.${license.code}`) }}
      </v-chip>
    </v-chip-group>
    <div>
      <v-select
        :items="ajokorttiLuokat"
        item-value="code"
        :item-text="(item) => $te(`licenses.${item.code}`) ? $t(`licenses.${item.code}`) : item.code"
        v-model="ajokorttiSelect"
        clearable
        counter
        multiple
        :label="$t('licenses.select_driving_license_classes')"
      ></v-select>
      <div class="d-flex flex-row justify-space-between">
        <v-checkbox
          v-for="licence in ajoLuvat.sort((a, b) => a.order - b.order)"
          :key="licence.value"
          :label="$t(`licenses.${licence.code}`)"
          class="mr-2"
          :input-value="selectedItems && selectedItems.includes(licence.code)"
          @change="toggleSelection(licence)"
        ></v-checkbox>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
