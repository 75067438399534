<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark :color="options.color">
        <v-toolbar-title class="white--text">{{ $t('email.send_failed_dialog_title') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-title>
        {{ $t('email.send_failed_dialog_subtitle', { count: response.errorCount }) }}
      </v-card-title>
      <v-card-text>
        <v-list>
          <template v-for="communication in response.failedCommunications">
            <v-list-item :key="`email_${communication.email}`">
              <v-list-item-icon>
                <v-icon color="red">mdi-alert-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ communication.email }}</v-list-item-title>
                <v-list-item-subtitle v-if="communication.errorMessage">
                  {{ communication.errorMessage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-divider></v-divider>
        <v-card-subtitle>{{ $t('email.send_failed_dialog_successful_communications') }}</v-card-subtitle>
        <v-list dense>
          <v-list-item v-for="communication in response.successfulCommunications" :key="communication.email">
            <v-list-item-icon>
              <v-icon color="green">mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ communication.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="cancel()">{{ $t('close') }}</v-btn>
        <v-btn text :color="options.color" @click.native="openCommunicationView()">{{ $t('email.open_communication_view') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "FailedEmailDialog",
  data() {
    return {
      dialog: false,
      errorCount: 0,
      response: {},
      options: {
        color: 'primary',
        width: 600,
      }
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.response = {};
      }
    },
  },
  methods: {
    open(communicationResponse) {
      this.response = communicationResponse;
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    async openCommunicationView() {
      this.dialog = false;
      this.$router.push({ name: 'communication' });
    },
  },

}
</script>

<style scoped>

</style>
