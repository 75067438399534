<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card :tile="$vuetify.breakpoint.xsOnly">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('job_ads.job_details') + ' - ' + job.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <JobBasicInfoForm :job="model" :customers="customers" />
      </v-card-text>
      <v-card-title class="text-break">
        <div class="subtitle-1">{{ $t('job_ads.schedule') }}</div>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="clearDates" text :disabled="!dateRange || (dateRange && dateRange.length < 2)" small>Tyhjennä</v-btn>
      </v-card-title>
      <v-card-text>
        {{ $t('job_ads.schedule_explained') }}
      </v-card-text>
      <v-card-text>
        <v-date-picker no-title show-week full-width range v-model="dateRange" :scrollable="$vuetify.breakpoint.mdAndUp"></v-date-picker>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="close"
               :disabled="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="save"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from '@/api/tenant';
import JobBasicInfoForm from "@/views/tenant/JobBasicInfoForm";
import moment from "moment/moment";

export default {
  name: 'EditJobBasicInfoDialog',
  components: {JobBasicInfoForm},
  props: {
    job: {
      type: Object,
      default: () => ({}),
    },
    customers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      model: {},
      dateRange: null,
    };
  },
  methods: {
    async open() {
      this.model = { ...this.job };
      this.dialog = true;
      this.loading = false;
      if (this.job.searchStartDate && this.job.searchEndDate) {
        this.dateRange = [moment(this.job.searchStartDate).format('YYYY-MM-DD'), moment(this.job.searchEndDate).format('YYYY-MM-DD')];
      }
    },
    close() {
      this.dialog = false;
      this.model = {};
      this.$emit('on-dismiss');
    },
    async save() {
      this.loading = true;

      if (this.dateRange && this.dateRange.length === 2) {
        this.dateRange = this.dateRange.sort((a, b) => {
          return moment(a).toDate() - moment(b).toDate();
        });
        this.model.searchStartDate = moment(this.dateRange[0]).set('hour', 0).set('minute', 0).set('second', 0).toISOString();
        this.model.searchEndDate = moment(this.dateRange[1]).set('hour', 23).set('minute', 59).set('second', 59).toISOString();
      }

      try {
        const jobAd = await tenantApi.updateJobAd(this.model.id, this.model);
        this.$emit('updated', jobAd);
        this.close();
      } catch (err) {
        this.$handleApiError(err);
      }
      this.loading = false;
    },
    clearDates() {
      this.dateRange = null;
    },
  },
}
</script>

