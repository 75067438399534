import Vue from 'vue';

export default new Vue({
  data() {
    return {
      innerUserInfo: JSON.parse(localStorage.getItem("userInfo" )),
      innerSession: JSON.parse(localStorage.getItem("session" )),
    }
  },
  methods: {
    setSession(session) {
      localStorage.setItem("session", JSON.stringify(session));
      this.innerSession = session;
    },
    getSession() {
      return this.innerSession;
    },
    setUserInfo(getMeResponse) {
      const userInfo = {};
      userInfo.roles = getMeResponse.roles;
      userInfo.firstName = getMeResponse.firstName;
      userInfo.lastName = getMeResponse.lastName;
      userInfo.username = getMeResponse.username;
      userInfo.phone = getMeResponse.phone;
      userInfo.agentUser = getMeResponse.agentUser;
      userInfo.enabled = getMeResponse.enabled;
      userInfo.subject = getMeResponse.subject;
      userInfo.id = getMeResponse.id;
      userInfo.imageKey = getMeResponse.imageKey;
      userInfo.email = getMeResponse.email;
      userInfo.tenants = getMeResponse.tenants;

      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      this.innerUserInfo = userInfo;
    },
    getUserInfo() {
      return this.innerUserInfo;
    },
    clearUserInfo() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("session");
      this.innerUserInfo = null;
      this.innerSession = null;
    }
  },
})
