import Vue from 'vue'
import Vuex from 'vuex'
import tenantApi from "./api/tenant";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    mainMenuOpen: false,
    notificationObject: {
      color: 'alert',
      message: '',
      timeOut: 4000,
      showButton: false,
      buttonText: '',
      callback: () => {},
    },
    loadingOverlay: false,
    bottomNavigation: false,
    jobAds: [],
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setLoadingOverlay(state, payload) {
      state.loadingOverlay = payload;
    },
    setJobAds(state, payload) {
      state.jobAds = payload;
    },
  },
  actions: {
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : '',
        callback: payload.showButton && payload.callback ? payload.callback : () => {},
      };
    },
    async reloadJobAds(context, payload) {
      let jobAds = [];
      if (payload.updateFromLikeit) {
        jobAds = await tenantApi.updateJobAdsFromLikeit();
      } else {
        jobAds = (await tenantApi.getJobAdsPaged(payload.filter, payload.options)).content;
      }
      context.commit('setJobAds', jobAds);
    },
  }
})
