<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark :color="options.color">
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-5" v-show="!!message">{{ message }}</v-card-text>
      <v-card-text v-if="options.comment">
        <v-form ref="form">
          <v-textarea :rules="[$rules.required]" v-model="comment" rows="3" :label="$t('comment')" />
          <div v-if="options.showResult">
            <div class="caption text-left">{{ $t('application.result_label') }}:</div>
            <v-btn-toggle color="primary" class="text-right" v-model="result" :mandatory="options.showResult">
              <template v-for="result in applicationResults">
                <v-btn v-if="$t(`application.result.${result}`)"
                       :x-small="$vuetify.breakpoint.smAndDown"
                       :small="$vuetify.breakpoint.mdAndUp"
                       color="primary"
                       outlined
                       :key="result"
                       :value="result">{{ $t(`application.result.${result}`) }}</v-btn>
              </template>
            </v-btn-toggle>
          </div>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="cancel()">{{options.cancel}}</v-btn>
        <v-btn text :color="options.color" v-if="options.agree" @click.native="agree()">{{options.agree}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'JobApplicationStatusChangeDialog',
  props: {
    applicationResults: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      comment: '',
      result: 'NONE',
      options: {
        color: 'primary',
        agree: this.$t('yes'),
        cancel: this.$t('no'),
        width: 440,
        comment: false,
        showResult: false,
      }
    };
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.resolve([true, this.comment, this.result]);
      this.$refs.form.resetValidation();
      this.comment = '';
      this.showResult = false;
      this.result = 'NONE';
      this.dialog = false;
    },
    cancel() {
      this.resolve([false, '', 'NONE']);
      this.comment = '';
      this.result = 'NONE';
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
}
</script>

<style scoped>

</style>
