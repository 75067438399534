import Vue from 'vue';
import Router from 'vue-router';
import LoginView from './views/LoginView.vue';

// Admin views
import UserManagementView from './views/UserManagementView.vue';
import Tenants from './views/TenantsView.vue';

// User views
import UserProfile from './views/UserProfile.vue';

// Tenant views
import TenantContainer from "./views/TenantContainer.vue";
import TenantUserManagementView from "./views/tenant/TenantUserManagementView.vue";
import SingleTenantTestView from "./views/tenant/SingleTenantTestView.vue";
import TenantManagementView from "./views/tenant/TenantManagementView.vue";
import JobAdsView from "@/views/tenant/JobAdsView";
import SingleJobAdView from "@/views/tenant/SingleJobAdView";
import CreateJobAdView from "@/views/tenant/CreateJobAdView";
import QuestionsManagementView from "@/views/tenant/QuestionsManagementView";
import OnlineTestManagementView from '@/views/tenant/OnlineTestManagementView';
import ApplicantsView from "@/views/tenant/ApplicantsView.vue";
import SingleJobApplicationView from "@/views/tenant/SingleJobApplicationView.vue";
import SingleApplicantView from "@/views/tenant/SingleApplicantView";
import DashboardView from "@/views/tenant/DashboardView.vue";
import CustomersView from '@/views/tenant/CustomersView';
import CommunicationView from '@/views/tenant/CommunicationView';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/',
      name: 'start',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/StartView.vue'),
    },
    {
      path: '/users',
      name: 'users',
      component: UserManagementView,
    },
    {
      path: '/me',
      name: 'userprofile',
      component: UserProfile,
    },
    {
      path: '/dummy',
      name: 'dummy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/StartView.vue'),
    },
    {
      path: '/t/:tenantId',
      props: true,
      component: TenantContainer,
      children: [
        {
          path: 'dashboard',
          name: 'tenant_dashboard',
          component: DashboardView,
        },
        {
          path: 'online_test_management',
          name: 'online_test_management',
          component: OnlineTestManagementView,
        },
        {
          path: 'job_ads',
          name: 'job_ads',
          component: JobAdsView,
        },
        {
          path: 'job_ads/j/:id',
          name: 'job_ad',
          props: true,
          component: SingleJobAdView,
        },
        {
          path: 'job_ads/j/:id/application/:applicationId',
          name: 'single_application',
          props: true,
          component: SingleJobApplicationView,
        },
        {
          path: 'job_ads/create',
          name: 'job_ad_create',
          component: CreateJobAdView,
        },
        {
          path: 'applicants',
          name: 'applicants',
          component: ApplicantsView,
        },
        {
          path: 'applicants/a/:id',
          name: 'single_applicant',
          component: SingleApplicantView,
          props: true,
        },
        {
          path: 'questions',
          name: 'questions',
          component: QuestionsManagementView,
        },
        {
          path: 'customers',
          name: 'customers',
          component: CustomersView,
        },
        {
          path: 'communication',
          name: 'communication',
          component: CommunicationView,
        },
        {
          path: 'tenant_test',
          name: 'tenant_test',
          props: true,
          component: SingleTenantTestView,
        },
        {
          path: 'users',
          name: 'tenant_users',
          props: true,
          component: TenantUserManagementView,
        },
        {
          path: 'settings',
          name: 'tenant_settings',
          props: true,
          component: TenantManagementView,
        }
      ]
    },
    {
      path: '/tenants',
      name: 'tenants',
      component: Tenants,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})
