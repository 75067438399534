<template>
  <div style="container-type: inline-size;">
    <v-select
      :items="allIndustries"
      item-text="name"
      item-value="code"
      v-model="selectedIndustries"
      clearable
      counter
      v-if="!hideSelect"
      multiple
      :label="$t('industries.select_experience')"
    >
      <template v-slot:item="{item, on, attrs}">
        <v-list-item v-bind="attrs">
          <v-checkbox
            v-model="selected[item.code]"
            :value="selectedIndustries.includes(item.code)"
            style="display: inline-block"
            @change="experienceChange"
          />
          <span class="col-4">
            {{ item.name }}
          </span>
          <v-slider
            class="col-7"
            v-model="years[item.code]"
            :label="yearsLabel(item)"
            min="0"
            max="6"
            @click.stop
            hide-details
            tick-size="5"
            ticks="always"
            @change="experienceChange"
          />
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "HbExperienceSelector",
  props: ['selectedItems', 'options', 'hideSelect', 'allIndustries'],
  data() {
    return {
      loading: false,
      selectedIndustries: [],
      selected: {},
      years: {},
    }
  },
  computed: {
  },
  methods: {
    yearsLabel(item) {
      const y = this.years[item.code];
      if (y > 5) {
        return `>${5}`;
      }
      return `${y}`;
    },
    experienceChange() {
      const selectedCodes = Object.entries(this.selected).filter(([k, v]) => v).map(([k, v]) => k);
      this.selectedIndustries = selectedCodes.map(code => ({code, years: this.years[code]}));
    },
  },
  watch: {
    selectedIndustries(to, from) {
      this.$emit('change', to);
    }
  },
  async mounted() {
    this.selected = {};
    this.years = {};
    for (const s of this.selectedItems) {
      this.selected[s.code] = true;
      this.years[s.code] = s.years;
    }
    const selectedCodes = Object.entries(this.selected).filter(([k, v]) => v).map(([k, v]) => k);
    this.selectedIndustries = selectedCodes.map(code => ({code, years: this.years[code]}));
  }
}
</script>

<style scoped>

</style>
