import i18n from '../i18n.js';

const onlineTestQuestionTypes = [
  { value: 'SELECT_MANY', text: i18n.t('questions_management.types.checklist') },
  { value: 'SELECT_ONE', text: i18n.t('questions_management.types.radiogroup') },
  { value: 'SCALE', text: i18n.t('questions_management.types.scale') },
];

const questionTypes = [
  { value: 'SELECT_MANY', text: i18n.t('questions_management.types.checklist') },
  { value: 'SELECT_ONE', text: i18n.t('questions_management.types.radiogroup') },
  { value: 'SCALE', text: i18n.t('questions_management.types.scale') },
  { value: 'FREE_TEXT', text: i18n.t('questions_management.types.text') },
];

const educationOptions = [
  { value: 'peruskoulu', text: i18n.t('education.education_levels.peruskoulu') },
  { value: 'lukio', text: i18n.t('education.education_levels.lukio') },
  { value: 'ammatillinen', text: i18n.t('education.education_levels.ammatillinen') },
  { value: 'amk', text: i18n.t('education.education_levels.amk') },
  { value: 'ylempiamk', text: i18n.t('education.education_levels.ylempiamk') },
  { value: 'korkeakoulu', text: i18n.t('education.education_levels.korkeakoulu') },
];

export { onlineTestQuestionTypes, questionTypes, educationOptions };
