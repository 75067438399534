<template>
  <v-container fluid fill-height class="py-0">

    <v-row no-gutters class="fill-height" align="center" style="z-index: 1;"  v-if="!$userInfo">
      <v-col cols="12" md="6" offset-md="3" lg="4" offset-lg="4" xl="4" offset-xl="4" class="login-holder px-3 pt-5 px-xl-16">

        <div class="text-center pb-5">
            <img style="height: auto; width: 90%;" src="/img/satahub_logo_1.svg" class="rounded-0 px-2"/>
        </div>

        <v-row>
          <v-card color="transparent" elevation="0" light style="width: 100%;" class="ma-0 pa-0">
            <v-row>
              <custom-sign-in v-if="!isSignUp && !isForgotPassword && !isRequireNewPassword"
                              :is-confirm-signup="isConfirmSignUp"
                              v-bind:signInConfig="signInConfig"
                              :uiComponentProperties="uiComponentProperties" />

              <custom-require-new-password v-if="isRequireNewPassword"
                                           v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"
                                           :uiComponentProperties="uiComponentProperties" />

              <custom-forgot-password class="forgot-password"
                                      v-if="isForgotPassword"
                                      v-bind:forgotPasswordConfig="forgotPasswordConfig"
                                      :uiComponentProperties="uiComponentProperties" />
            </v-row>
          </v-card>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UiEventBus from '../UiEventBus'
import { components as amplifyComponents, AmplifyEventBus } from 'aws-amplify-vue'
import CustomSignIn from '../components/Login/SignIn.vue';
import CustomForgotPassword from '../components/Login/ForgotPassword.vue';
import CustomRequireNewPassword from '../components/Login/RequireNewPassword.vue';

export default {
  name: "LoginView",
  data() {
    return {
      uiComponentProperties: {
        rounded: false,
        text: false,
        outlined: true,
      },
      signInConfig: {
        header: this.$t('login.login'),
        isSignUpDisplayed: false,
      },
      signUpConfig: {
        defaultCountryCode: '358',
        signUpFields: [
          {
            label: this.$Amplify.I18n.get('Username'),
            key: 'username',
            required: true,
            type: 'string',
            displayOrder: 1
          },
          {
            label: this.$Amplify.I18n.get('Email'),
            key: 'email',
            required: true,
            type: 'string',
            displayOrder: 2
          },
        ],
        hiddenDefaults: ['phone_number', 'username']
      },
      authConfig: {},
      forgotPasswordConfig: {},
      isSignUp: false,
      isConfirmSignUp: false,
      isForgotPassword: false,
      isRequireNewPassword: false,
    }
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {},
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {}
      };
      return Object.assign(defaults, this.authConfig)
    }
  },
  methods: {
    async getCurrentUser() {
      try {
        const cognitoUser = await this.$Amplify.Auth.currentAuthenticatedUser();
        UiEventBus.$emit('userLoggedIn', cognitoUser)
        this.$store.commit('setLoadingOverlay', true);
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    logout() {
      // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
      this.$Amplify.Auth.signOut().then(() => {
        // this is client side only logout
        UiEventBus.$emit('userLoggedOut');
      })
    }
  },
  created() {
    AmplifyEventBus.$on('localUser', user => {
      this.user = user;
      this.options.signInConfig.username = this.user.username;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.username = this.user.username;
      this.options.requireNewPasswordConfig.user = this.user;
    });
    AmplifyEventBus.$on('authState', state => {
      this.isRequireNewPassword = state === 'requireNewPassword';
      this.isSignUp = state === 'signUp';
      this.isConfirmSignUp = state === 'confirmSignUp';
      this.isForgotPassword = state === 'forgotPassword';
      if (state === 'signedIn') {
        this.getCurrentUser();
      }
    });
  },
  mounted() {
    if (this.$isLoggedIn) {
      this.$store.commit('setLoadingOverlay', false);
      this.$router.push({ name: 'start' });
    }
  },
  components: {
    CustomSignIn,
    CustomForgotPassword,
    CustomRequireNewPassword,
    ...amplifyComponents
  },
}
</script>

<style lang="scss" scoped>
</style>
