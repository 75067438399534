class Helper {
  static tableOptionsToRestPageParams(options) {
    return {
      pageNo: options.page || 1,
      pageSize: options.itemsPerPage || 50,
      sortBy: (options.sortBy || []).map((s, i) => s + ':' + (options.sortDesc[i] ? 'DESC' : 'ASC')).join(',') || void 0,
    };
  }
}

export default Helper;
