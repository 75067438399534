<template>
  <div>
    <v-list dense v-if="files && files.length > 0">
      <v-list-item v-for="file in files" :key="file.id">
        <v-list-item-content>
          <v-list-item-title>
            {{ file.name || file.filename }}
            <span class="caption ml-2">{{ $formatDateNoTime(file.created)}}</span>
          </v-list-item-title>
          <v-list-item-subtitle v-if="file.application">
            {{ file.application.jobAd.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon class="primary" dark @click="downloadCV(file.id)">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <template v-else>
      <NoContentView :title="noFilesTitle" :description="noFilesMessage" :small="true" :hide-button="true" icon="mdi mdi-file-document" />
    </template>
    <v-btn
      v-if="showAdd"
      @click="changeCV"
      small
      class="ma-3"
      :loading="loadingFile">
      <v-icon left>mdi-plus</v-icon>
      {{ $t('applicant.add_file') }}
    </v-btn>
    <input type="file" style="display:none" :multiple="false" ref="cvFileInput" @change="onCVFileChanged">
  </div>
</template>
<script>
import tenantApi from '@/api/tenant';
import NoContentView from "@/components/NoContentView";

export default {
  name: 'applicant-cv',
  components: {NoContentView},
  props: ['applicant', 'application', 'type', 'showAdd', 'files', 'noFilesTitle', 'noFilesMessage'],
  data() {
    return {
      loadingFile: false,
      uploadPercentCompleted: 0,
    };
  },
  methods: {
    changeCV() {
      if (!this.loadingCV) {
        this.$refs.cvFileInput.click();
      }
    },
    clearCV() {
      this.file = {};
      this.$refs.cvFileInput.value = null;
    },
    async onCVFileChanged(event) {
      this.loadingFile = true;

      const f = event.target.files[0];
      const asDataUrl = await this.toBase64(f);
      this.file = {
        file: f,
        dataUrl: asDataUrl,
        name: f.name,
        type: f.type,
        filename: f.name,
        fileDescription: '',
        size: f.size,
      };
      event.target.value = '';

      const handleUploadProgress = (progressEvent) => {
        // TODO: tää näkyviin ehkä johki tai joku loading pylpyrä?
        this.uploadPercentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log('uploadPercentCompleted', this.uploadPercentCompleted);
      };

      if (this.file.file) {
        if (this.type === 'CV') {
          await tenantApi.uploadCv(this.applicant.id, this.application?.id, this.file, handleUploadProgress);
        } else {
          await tenantApi.uploadAdditionalFile(this.applicant.id, this.application?.id, this.file, handleUploadProgress);
        }
      }

      this.$emit('updated');

      this.loadingFile = false;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async createFileUrl(file) {
      let a = await tenantApi.downloadFile(file.id);
      const blob = new Blob([a], { type: file.contentType });
      return URL.createObjectURL(blob);
    },
    async downloadCV(id) {
      console.log('downloadCV', id);
      const link = document.createElement('a');
      const file = this.files.find(f => f.id === id);
      link.href = await this.createFileUrl(file);
      link.download = file.name || file.filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
}
</script>
