<template>
  <v-form ref="form">
    <v-autocomplete
      class="mt-0"
      :label="$t('job_ads.detail.industry')"
      v-model="model.industryCode"
      :items="industries"
      item-text="name"
      item-value="code"
      clearable
    />
    <v-autocomplete
      class="mt-0"
      :label="$t('job_ads.detail.alternative_industries')"
      v-model="model.alternativeIndustryCodes"
      :items="industries"
      item-text="name"
      item-value="code"
      multiple
      deletable-chips
      chips
      clearable
    />

    <v-text-field
        type="text"
        validate-on-blur
        :label="$t('job_ads.detail.type')"
        v-model="model.type"
    ></v-text-field>
    <v-text-field
        class="mt-0"
        type="text"
        validate-on-blur
        :label="$t('job_ads.detail.length')"
        v-model="model.length"
    ></v-text-field>
    <v-text-field
        class="mt-0"
        type="text"
        validate-on-blur
        :label="$t('job_ads.detail.location')"
        v-model="model.location"
    ></v-text-field>
    <v-text-field
        class="mt-0"
        type="text"
        validate-on-blur
        :label="$t('job_ads.detail.starts')"
        v-model="model.starts"
    ></v-text-field>
    <v-text-field
        class="mt-0"
        type="text"
        validate-on-blur
        :label="$t('job_ads.detail.salary')"
        v-model="model.salary"
    ></v-text-field>
    <v-autocomplete
        class="mt-0"
        :label="$t('job_ads.detail.contact_name')"
        v-model="model.contactEmail"
        :items="contactPersons"
        :item-text="contactPersonItemText"
        item-value="email"
        clearable
    ></v-autocomplete>
    <v-row no-gutters class="mx-n2">
      <v-col cols="12">
        <v-card color="grey lighten-4">
          <v-card-title class="px-2 subtitle-2 secondary--text">
            {{ $t('job_ads.detail.link') }}
          </v-card-title>
          <v-card-text class="px-2">
            <v-text-field
              class="mt-0"
              type="url"
              validate-on-blur
              :label="$t('job_ads.detail.link_url')"
              v-model="model.link"
            ></v-text-field>
            <v-textarea
              class="mt-0"
              v-if="model.link && model.link.length > 0"
              v-model="model.linkDescription"
              rows="3"
              :hint="$t('job_ads.detail.link_description_hint')"
              persistent-hint
              :label="$t('job_ads.detail.link_description')">
            </v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card color="grey lighten-4 mt-3">
          <v-card-title class="px-2 subtitle-2 secondary--text">
            {{ $t('job_ads.detail.youtube_link') }}
          </v-card-title>
          <v-card-text class="px-2">
            <v-text-field
              class="mt-0"
              type="url"
              validate-on-blur
              :label="$t('job_ads.detail.youtube_link_url')"
              v-model="model.youtubeLink"
            ></v-text-field>
            <v-textarea
              class="mt-0"
              v-if="model.youtubeLink && model.youtubeLink.length > 0"
              v-model="model.youtubeLinkDescription"
              rows="3"
              :hint="$t('job_ads.detail.youtube_link_description_hint')"
              persistent-hint
              :label="$t('job_ads.detail.youtube_link_description')">
            </v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  name: 'JobDetailsForm',
  props: {
    contactPersons: {},
    model: {},
    industries: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    contactPersonItemText(user) {
      let text = user.email || user.username;
      if (user.firstName && user.lastName) {
        text += ` (${user.firstName} ${user.lastName})`;
      }
      return text;
    },
  },
}
</script>
