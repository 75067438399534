/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue'
import moment from 'moment/moment'
import md5 from 'md5';

import adminApi from "../api/admin";
import userApi from "../api/user";
import systemApi from "../api/system";
import tenantApi from "../api/tenant";
import tenantService from '../service/tenant.service';
import store from "../store";
import i18n from "../i18n";
import {isNumber} from "lodash";

moment.locale('fi');

const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $tenant() {
          const savedTenant = tenantService.tenant;
          if (savedTenant != null) {
            return savedTenant;
          }

          if (savedTenant == null && this.$userInfo.tenants.length > 0) {
            return this.$userInfo.tenants[0].id;
          }

          return null;
        },
        $likeitEnabled() {
          return false;
        },
        $userApi() {
          return userApi;
        },
        $adminApi() {
          return adminApi;
        },
        $systemApi() {
          return systemApi;
        },
        $tenantApi() {
          return tenantApi;
        },
        $baseUrl() {
          const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') === -1;
          if (hasCustomBaseUrl) {
            return process.env.VUE_APP_API_BASEURL;
          }
          return '';
        },
        $bottomNavigationStyle() {
          if (this.$vuetify.breakpoint.xsOnly) {
            return "z-index: 5; bottom: 72px"
          }
          return '';
        },
        $loremIpsum() {
          return "Possim et sed sed consetetur dolore aliquam labore et amet vel. Tempor diam tempor lorem diam et dolor takimata rebum at quis nam quis. Justo nonumy labore amet sed elitr est facilisi dolor erat dolor sadipscing magna euismod sed sadipscing dolor gubergren sit. Vero lorem et augue dolores magna ea et. Voluptua et hendrerit consequat et lorem erat amet accusam labore. Eos lorem at no ipsum ea feugait. Gubergren facer voluptua sit aliquip dolor dolor delenit amet ut sed laoreet sea accusam duo voluptua clita et. Tempor rebum dolor consetetur et et no invidunt. Sed rebum veniam consectetuer aliquyam soluta amet lorem possim. Ut est duo wisi magna ad eum sed ipsum."
        },
      },
      methods: {
        $showSuccessNotification(message) {
          store.dispatch('showNotification', {
            color: 'success',
            message,
            showButton: false,
          });
        },
        $showWarningNotification(message) {
          store.dispatch('showNotification', {
            color: 'warning',
            message,
            showButton: false,
          });
        },
        $showErrorNotification(message) {
          const msg = message != null ? message : i18n.t('generic_error');
          store.dispatch('showNotification', {
            color: 'error',
            message: msg,
            showButton: false,
          });
        },
        /**
         *
         * @param {!error} err
         * @param {msg} [msg] message to show
         */
        $handleApiError(err, msg) {
          console.log(err);
          if (err.response != null && err.response.data && err.response.data.error_key != null) {
            this.$showErrorNotification(i18n.t(err.response.data.error_key));
          } else {
            this.$showErrorNotification(msg);
          }
        },
        $reloadApp() {
          location.reload();
        },
        $formatTimeStamp(date) {
          return moment(date).format('LLLL');
        },
        $formatTimeStampShort(date) {
          return date ? moment(date).format('L') : '';
        },
        $formatDateNoTime(date) {
          return date ? moment(date).format('DD.MM.YYYY') : '';
        },
        $formatTimeNoDate(date) {
          if (date) {
            return moment(date).format('HH:mm');
          }
        },
        $humanizeDuration(duration, showSeconds = false) {
          if (duration.asHours() > 23) {
            return Math.floor(duration.asHours()) + 'h ' + duration.minutes() + 'min';
          }
          return duration.hours() + 'h ' + duration.minutes() + 'min ' + (showSeconds ? duration.seconds() + 's' : '');
        },
        $randomizeColorFromString(string, saturation = 75, lightness = 60) {
          let hash = 0;
          if (string.length > 0) {
            for (var i = 0; i < string.length; i++) {
              hash = string.charCodeAt(i) + ((hash << 5) - hash);
              hash = hash & hash; // Convert to 32bit integer
            }
          }

          if (hash < 0) hash = hash * -1;

          let shortened = hash % 200;
          return `hsl(${shortened}, ${saturation}%, ${lightness}%)`;
        },
        $getImageUrl(imageKey) {
          return `${this.$baseUrl}/api/public/image/${imageKey}`;
        },
        $getGravatarUrl(email, size) {
          const picSize = size ? size : '68';
          const hash = md5((email.toLowerCase()).trim());
          return `https://www.gravatar.com/avatar/${hash}?s=${picSize}&d=mm`; // eslint-disable-line no-undef
        },
      }
    });
  },
};

export default HelperPlugin;
