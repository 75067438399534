<template>
  <v-snackbar :value="showSnackbar" top right timeout="-1">
    <div>
      <div class="subtitle-1 mb-2">{{ $t('questions_management.answer_test_mode_is_on') }}</div>
      <div class="mb-3">
        <template  v-for="question in questions">
          <div v-if="selectedAnswers.findIndex(a => a.questionId === question.id) > -1" :key="question.id" class="mb-2" style="line-height: 0.85em;">
            <div class="caption">{{ question.questionText }}</div>
            <span v-for="(answer, index) in questionSelectedAnswers(question)" :key="answer.id">
              <template v-if="index > 0"> - </template>
              <span>
                <strong>{{ answer.answerText }}</strong> ({{  answer.tags.length > 0 ? answer.tags.join(', ') : $t('questions_management.no_tags') }})
              </span>
            </span>
          </div>
        </template>
      </div>
    </div>

    <v-btn small @click="clearAnswers" block>{{ $t('questions_management.leave_test_mode_and_reset') }}</v-btn>

  </v-snackbar>
</template>

<script>
export default {
  name: "AnswerTestSnackbar",
  props: ['selectedAnswers', 'questions'],
  data() {
    return {
    }
  },
  methods: {
    clearAnswers() {
      this.$emit('handle-reset');
    },
    questionSelectedAnswers(question) {
      return this.selectedAnswers.filter(a => a.questionId === question.id);
        /*.map(a => {
        return `${a.answerText} - ${a.tags.join(', ')}`;
      }).join(', ')*/
    }
  },
  computed: {
    showSnackbar() {
      return this.selectedAnswers.length > 0;
    }
  }
}
</script>

<style scoped>

</style>
