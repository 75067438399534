var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hb-basic-page',{attrs:{"title":_vm.$t('communication.title'),"loading":_vm.loading,"subtle-loading":_vm.subtleLoading,"fill-height":_vm.loading,"no-gutters":true,"name":"communication-view"}},[_c('v-row',{staticClass:"flex-wrap",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"page":_vm.pageNo,"items-per-page":_vm.pageSize,"footer-props":{ itemsPerPageOptions: [50, 100, 500, -1] },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":true,"loading":_vm.subtleLoading,"no-data-text":_vm.$t('communication.no_data'),"no-results-text":_vm.$t('communication.no_data')},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.reloadItems],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.reloadItems],"pagination":_vm.paginate,"click:row":_vm.expand},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(item.subject))]),(item.expanded)?_c('div',{staticClass:"py-2 mx-n2 px-2 grey lighten-5 rounded caption",staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(item.message))]):_vm._e()])]}},{key:"item.recipientCount",fn:function(ref){
var item = ref.item;
return [(!item.expanded)?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.recipients.length))]):_vm._e(),(item.expanded)?_vm._l((item.recipients),function(r){return _c('div',{key:r.value,staticClass:"caption",staticStyle:{"line-height":"14px"}},[_vm._v(" "+_vm._s(_vm.recipientText(r))+" ")])}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t(("communication.statuses." + (item.status)))))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$formatTimeStamp(item.createdAt)))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }