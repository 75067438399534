<template>
  <hb-basic-page :title="$t('customers.title')"
                 :loading="loading"
                 :subtle-loading="subtleLoading"
                 :fill-height="loading"
                 :search-title="`${$t('customers.search')}...`"
                 @handle-search="handleSearch"
                 :no-gutters="true"
                 name="customers-view">
    <v-col cols="12" class="pt-0 flex-shrink-1" :style="{height: $vuetify.breakpoint.xsOnly ? '0' : '60px'}">
      <v-fab-transition>
        <v-btn
          @click="createCustomer"
          color="primary"
          dark
          :disabled="loading"
          :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('customers.add_new_customer') }}</span>
        </v-btn>
      </v-fab-transition>
    </v-col>
    <v-row class="flex-wrap" justify="center">
      <v-col cols="12">
        <v-data-table :headers="headers"
                      :items="filteredCustomers"
                      :loading="subtleLoading"
                      :no-data-text="$t('customers.no_customers')"
                      :no-results-text="$t('customers.no_customers')">
          <template v-slot:item.industry="{ item }">
            {{ industryName(item) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small icon @click.stop="editCustomer(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn small icon @click.stop="deleteCustomer(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add-or-edit-customer-dialog
      ref="addOrEditCustomerDialog"
      :industries="allIndustries"
      :customers="customers"
      @save="saveCustomer"
    />
    <confirm-dialog ref="confirmDialog" />
  </hb-basic-page>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog';
import AddOrEditCustomerDialog from '@/components/Tenant/Customers/AddOrEditCustomerDialog';

export default {
  name: 'CustomersView',
  components: { AddOrEditCustomerDialog, ConfirmDialog},
  data() {
    return {
      loading: false,
      subtleLoading: false,
      searchPhrase: '',
      allIndustries: [],
      headers: [
        { text: this.$t('customers.name'), value: 'name', sortable: true, width: '35%' },
        { text: this.$t('customers.business_id'), value: 'businessId', sortable: true, width: '15%' },
        { text: this.$t('customers.industry'), value: 'industry', sortable: true, width: '15%' },
        { text: '', value: 'actions', align: 'right', sortable: false, width: '15%' },
      ],
      customers: [],
    };
  },
  computed: {
    filteredCustomers() {
      return this.customers.filter(c => {
        const name = c.name || '';
        const businessId = c.businessId || '';
        const industryName = this.industryName(c);
        const s = `${name} ${businessId} ${industryName}`.toLowerCase();
        return s.includes(this.searchPhrase);
      });
    }
  },
  methods: {
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    async reloadIndustries() {
      this.loading = true;
      try {
        this.allIndustries = await this.$tenantApi.getIndustries();
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loading = false;
    },
    async reloadCustomers() {
      this.subtleLoading = true;
      try {
        this.customers = await this.$tenantApi.getCustomers();
      } catch (e) {
        this.$handleApiError(e);
      }
      this.subtleLoading = false;
    },
    createCustomer() {
      this.$refs.addOrEditCustomerDialog.open();
    },
    editCustomer(item) {
      this.$refs.addOrEditCustomerDialog.open(item);
    },
    async deleteCustomer(item) {
      const confirm = await this.$refs.confirmDialog.open(
        this.$t('customers.confirm_delete_title'),
        this.$t('customers.confirm_delete_message'),
        {
          color: 'error',
          agree: this.$t('customers.confirm_delete_confirm'),
          cancel: this.$t('customers.confirm_delete_cancel')
        });
      if (!confirm) {
        return;
      }

      this.loading = true;
      try {
        await this.$tenantApi.deleteCustomer(item.id);
        this.customers = this.customers.filter(c => c.id !== item.id);
        this.$showSuccessNotification(this.$t('customers.customer_deleted'))
      } catch (error) {
        this.$handleApiError(error);
      }
      this.loading = false;
    },
    async saveCustomer(customer) {
      this.subtleLoading = true;
      try {
        if (customer.id) {
          await this.$tenantApi.updateCustomer(customer.id, customer);
        } else {
          await this.$tenantApi.createCustomer(customer);
        }
        await this.reloadCustomers();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.subtleLoading = false;
    },
    industryName(item) {
      const industry = this.allIndustries.find(i => i.code === item.industryCode);
      return industry ? industry.name : '';
    },
  },
  async mounted() {
    await this.reloadIndustries();
    await this.reloadCustomers();
  }
}
</script>

<style scoped>

</style>
