<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark :color="options.color">
        <v-toolbar-title class="white--text">{{ $t('email.title') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-textarea :value="recipientsText" :label="$t('email.recipients')"  readonly class="mt-2" rows="4" />
          <v-text-field v-model="subject" :label="$t('email.subject')" :rules="[$rules.required]" class="mt-2" />
          <v-textarea v-model="message" :label="$t('email.message')" :rules="[$rules.required]" class="mt-2" rows="4" />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="cancel()">{{ $t('email.cancel') }}</v-btn>
        <v-btn text :color="options.color" @click.native="agree()">{{ $t('email.send') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SendEmailDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      recipients: [],
      subject: '',
      message: '',
      options: {
        color: 'primary',
        agree: this.$t('yes'),
        cancel: this.$t('no'),
        width: 600,
      }
    };
  },
  methods: {
    open(options, recipients) {
      options ||= {};
      this.dialog = true;
      this.options = { ...this.options, ...options };
      this.recipients = [ ...recipients ];
      this.subject = '';
      this.message = '';
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.resolve([true, {
        recipients: this.recipients,
        subject: this.subject,
        message: this.message
      }]);
      this.$refs.form.resetValidation();
      this.recipients = [];
      this.subject = '';
      this.message = '';
      this.dialog = false;
    },
    cancel() {
      this.resolve([false, {}]);
      this.recipients = [];
      this.subject = '';
      this.message = '';
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
  computed: {
    recipientsText() {
      return this.recipients.join('\n');
    },
  }
}
</script>

<style scoped>

</style>
