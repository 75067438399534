<template>
  <hb-basic-page :title="$t('job_ads.create')"
                 :loading="loading"
                 :subtle-loading="subtleLoading"
                 :fill-height="loading"
                 no-gutters
                 :back-button="{show: true, fallback: 'job_ads'}"
                 name="job-ad-create-view">

    <v-col cols="12" md="8" xl="6" offset-md="2" offset-xl="3">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="headline text-break">
              {{ $t('job_ads.job_details') }}
            </v-card-title>
            <v-card-text>
              <job-basic-info-form :job="job" :customers="customers"/>
            </v-card-text>
          </v-card>

          <v-card class="mt-6">
            <v-card-title class="text-break">
              <div class="headline">{{ $t('job_ads.schedule') }}</div>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="clearDates" text :disabled="!dateRange || (dateRange && dateRange.length < 2)" small>Tyhjennä</v-btn>
            </v-card-title>
            <v-card-text>
              {{ $t('job_ads.schedule_explained') }}
            </v-card-text>
            <v-card-text>
              <v-date-picker no-title show-week full-width :min="minDate" range v-model="dateRange" :scrollable="$vuetify.breakpoint.mdAndUp"></v-date-picker>
            </v-card-text>
          </v-card>

          <v-card class="mt-6">
            <v-card-title class="headline text-break">
              {{ $t('job_ads.additional_info') }}
            </v-card-title>
            <v-card-text>
              <job-details-form :contact-persons="contactPersons"
                                :industries="industries"
                                :model="job" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="save" block>
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

  </hb-basic-page>
</template>

<script>
import JobDetailsForm from "@/components/Tenant/SingleJobAd/JobDetailsForm";
import tenantApi from "@/api/tenant";
import moment from "moment";
import JobBasicInfoForm from "@/views/tenant/JobBasicInfoForm";

export default {
  name: "CreateJobAdView",
  components: {JobBasicInfoForm, JobDetailsForm},
  data() {
    return {
      loading: true,
      subtleLoading: false,
      job: {
        name: '',
        description: '',
        positions: "1",
        searchStartDate: null,
        searchEndDate: null,
        type: '',
        length: '',
        location: '',
        starts: '',
        salary: '',
        contactEmail: '',
        link: '',
        linkDescription: '',
        youtubeLink: '',
        youtubeLinkDescription: '',
      },
      contactPersons: [],
      dateRange: null,
      minDate: moment().format('YYYY-MM-DD'),
      industries: [],
      customers: [],
    }
  },
  methods: {
    async reloadCustomers() {
      try {
        this.customers = await tenantApi.getCustomers();
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    async reloadContactPersons() {
      try {
        this.contactPersons = await tenantApi.loadTenantUsers();
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    async reloadIndustries() {
      try {
        this.industries = await tenantApi.getIndustries();
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    async save() {
      this.saving = true;

      if (this.dateRange && this.dateRange.length === 2) {
        this.dateRange = this.dateRange.sort((a, b) => {
          return moment(a).toDate() - moment(b).toDate();
        });
        this.job.searchStartDate = moment(this.dateRange[0]).set('hour', 0).set('minute', 0).set('second', 0).toISOString();
        this.job.searchEndDate = moment(this.dateRange[1]).set('hour', 23).set('minute', 59).set('second', 59).toISOString();
      }

      try {
        const job = await tenantApi.createJobAd(this.job);
        this.$router.replace({ name: 'job_ad', params: { id: job.id } });
      } catch (e) {
        this.$handleApiError(e, this.$t('errors.error_saving_job'));
      }
      this.saving = false;
    },
    clearDates() {
      this.dateRange = null;
    },
  },
  async mounted() {
    await this.reloadContactPersons();
    await this.reloadIndustries();
    await this.reloadCustomers();
    this.loading = false;
  }
}
</script>

