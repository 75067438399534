<template>
  <hb-basic-page :loading="true">

  </hb-basic-page>
</template>

<script>
export default {
  name: 'StartView',
  mounted() {
    if (this.$tenant != null) {
      this.$router.push({ name: 'job_ads', params: { tenantId: this.$tenant }});
    } else if (this.$isAdmin) {
      this.$router.push({ name: 'users' });
    }
  }
}
</script>

<style scoped>

</style>
