<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card :tile="$vuetify.breakpoint.xsOnly">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ newQuestion ? $t('questions_management.add_new_question') : $t('questions_management.edit_question') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="scroll-area">
        <v-card-text class="px-4 pb-14">
          <v-alert :value="isCopy" type="warning" text>
            {{ $t('questions_management.copy_create_info') }}
          </v-alert>
          <EditQuestionForm ref="editQuestionForm" :job="job" :online-test="onlineTest" :is-new="false" />
        </v-card-text>
      </div>

      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-btn color="default"
               v-if="$vuetify.breakpoint.smAndUp && !isCopy"
               @click="createCopy"
               :disabled="loading">
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('questions_management.confirm_create_copy_confirm') }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :disabled="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveQuestion"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

  </v-dialog>
</template>

<script>
import EditQuestionForm from "@/components/Tenant/EditQuestionForm";
import ConfirmDialog from "../ConfirmDialog";

export default {
  name: 'EditQuestionDialog',
  components: {ConfirmDialog, EditQuestionForm},
  props: ['job', 'onlineTest'],
  data() {
    return {
      dialog: false,
      loading: false,
      ready: false,
      questionId: -1,
      question: null,
      isCopy: false,
    }
  },
  methods: {
    async createCopy() {
      this.question.jobAds = [];
      const question = {...this.question};
      question.id = -1;
      try {
        await this.$refs.editQuestionForm.setQuestionForEdit(question);
        this.isCopy = true;
      } catch (error) {
        console.error(error);
      }
    },
    open(question) {
      this.dialog = true;
      // Without next tick, editquestionform ref is not there yet and there is an error
      this.$nextTick((async () => {
        if (question && question.id) {
          this.questionId = question.id;
          this.question = question;
          await this.$refs.editQuestionForm.setQuestionForEdit(question);
        } else {
          await this.$refs.editQuestionForm.setNewQuestion();
        }
      }));
    },
    async saveQuestion() {
      let createCopy = false;

      if (this.question && this.question.jobAds.length > 0) {
        if (!(this.question.jobAds.length === 1 && this.question.jobAds.some(j => j.id === this.job?.id))) {
          const confirm = await this.$refs.confirmDialog.open(
            this.$t('questions_management.confirm_create_copy'),
            this.$t('questions_management.confirm_create_copy_message'),
            {
              color: 'primary',
              agree: this.$t('questions_management.confirm_create_copy_confirm'),
              cancel: this.$t('questions_management.confirm_create_copy_cancel')
            });
          createCopy = confirm;
        }
      }

      this.loading = true;

      try {
        const question = await this.$refs.editQuestionForm.handleSave(createCopy);
        this.dialog = false;
        if (this.newQuestion && !this.isCopy) {
          this.$emit('question-created', question);
        } else if (createCopy || this.isCopy) {
          this.$emit('question-copy-created', question, this.question);
        } else {
          this.$emit('question-updated', question);
        }
      } catch (error) {
        this.$handleApiError(error);
      }

      this.loading = false;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.ready = false;
        this.questionId = -1;
        this.loading = false;
        this.isCopy = false;
        this.$emit('on-dismiss');
      }
    }
  },
  computed: {
    newQuestion() {
      return this.questionId === -1;
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true
      }, 300);
    } else {
      this.ready = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-area {
  max-height: calc(100vh - 104px);
  overflow: auto;
}

@media only screen and (min-width: 600px) {
  .scroll-area {
    height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
  }
}
</style>

