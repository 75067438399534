import axios from 'axios';
import OnlineTestService from '@/jobs/service/onlinetest.service';

const sharedApi = {
  setTenant() {
    const tenant = OnlineTestService.getTenant();//window.online_test_tenant_id || localStorage.getItem('tenant');
    if (tenant) {
      axios.defaults.headers.common['X-tenant-id'] = tenant;
    }
  },
  async getOnlineTestQuestions() {
    this.setTenant();
    const response = await axios.get('/api/shared/online_test/question');
    return response.data;
  },
  async getJobAd(jobAdId) {
    this.setTenant();
    const response = await axios.get(`/api/shared/online_test/job/${jobAdId}`);
    return response.data;
  },
  async getJobAdPreview(jobAdId) {
    this.setTenant();
    const response = await axios.get(`/api/shared/online_test/job/${jobAdId}/preview`);
    return response.data;
  },
  async getJobAds(answers) {
    this.setTenant();
    const response = await axios.post('/api/shared/online_test/job', answers);
    return response.data;
  },
  async logJobAdRead(jobAdId) {
    const params = {
      type: 'read',
    }
    const response = await axios.post(`/api/shared/online_test/job/${jobAdId}/log`, null, { params });
    return response.data;
  },
  async logJobAdSubmit(jobAdId) {
    const params = {
      type: 'submit',
    }
    const response = await axios.post(`/api/shared/online_test/job/${jobAdId}/log`, null, { params });
    return response.data;
  },
  async logJobAdNextQuestion(jobAdId, questionId) {
    const params = {
      type: 'next',
      questionId,
    }
    const response = await axios.post(`/api/shared/online_test/job/${jobAdId}/log`, null, { params });
    return response.data;
  },
  async leaveContactInfo(contactInfo) {
    this.setTenant();
    const response = await axios.post('/api/shared/online_test/contact', {
      ...contactInfo,
      answers: OnlineTestService.getAnswers(),
      textAnswers: OnlineTestService.getTextAnswers(),
    });
    return response.data;
  },
  async applyForJob(jobAdId, contactInfo) {
    this.setTenant();
    const response = await axios.post(`/api/shared/online_test/job/${jobAdId}`, {
      ...contactInfo,
      answers: OnlineTestService.getAnswers(),
      textAnswers: OnlineTestService.getTextAnswers(),
    });
    return response.data;
  },
  async getOpenApplication() {
    this.setTenant();
    const response = await axios.get(`/api/shared/online_test/open_application`);
    return response.data;
  },
  async uploadCv(applicantId, applicationId, file, uploadProgressCallBack) {
    const formData = new FormData();
    formData.append('file', file.file, file.filename);
    formData.append('name', file.name);
    formData.append('type', file.type);
    const response = await axios.post(`/api/shared/online_test/${applicantId}/cv/${applicationId}`, formData, { onUploadProgress: uploadProgressCallBack });
    return response.data;
  },
  async uploadAdditionalFile(applicantId, applicationId, file, uploadProgressCallBack) {
    const formData = new FormData();
    formData.append('file', file.file, file.filename);
    formData.append('name', file.name);
    formData.append('type', file.type);
    const response = await axios.post(`/api/shared/online_test/${applicantId}/file/${applicationId}`, formData, { onUploadProgress: uploadProgressCallBack });
    return response.data;
  },
  async getLicenses() {
    this.setTenant();
    const response = await axios.get(`/api/shared/online_test/license`);
    return response.data;
  },
  async getIndustries() {
    this.setTenant();
    const response = await axios.get(`/api/shared/online_test/industry`);
    return response.data;
  },
};

export default sharedApi;
