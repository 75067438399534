<template>
  <div>
    <div class="title mb-3">{{ $t('questions_management.question_details') }}</div>
    <v-text-field :label="$t('questions_management.question_title')" v-model="questionText"></v-text-field>
    <v-select :label="$t('questions_management.question_type')" :items="questionTypes" v-model="type" :disabled="!isNewQuestion"></v-select>
    <!--
    <v-switch v-if="!onlineTest" v-model="quiz" :disabled="!checkIfQuestionNeedsAnswers(type)" :label="$t('questions_management.show_in_quiz')"/>
    <v-divider></v-divider>
    -->
    <div class="title mt-4 mb-3">{{ $t('questions_management.tags') }}</div>
    <v-alert type="info" :value="true" text dense>
      {{ $t('questions_management.tags_help') }}
    </v-alert>
    <v-col cols="12">
      <v-combobox outlined
                  :label="$t('job_ads.required_tags')"
                  :items="allTags"
                  v-model="requiredTags"
                  item-value="id"
                  item-text="description"
                  @input="tagsOnInput"
                  multiple
                  deletable-chips
                  chips
                  small-chips
                  hide-details></v-combobox>
    </v-col>
    <v-col cols="12">
      <v-combobox outlined
                  :label="$t('job_ads.exclusive_tags')"
                  :items="allTags"
                  v-model="exclusiveTags"
                  item-value="id"
                  item-text="description"
                  @input="tagsOnInput"
                  multiple
                  deletable-chips
                  chips
                  small-chips
                  hide-details></v-combobox>
    </v-col>
    <v-col cols="12">
      <v-combobox outlined
                  :label="$t('questions_management.classification_tags')"
                  :items="allTags"
                  v-model="classificationTags"
                  item-value="id"
                  item-text="description"
                  @input="tagsOnInput"
                  multiple
                  deletable-chips
                  chips
                  small-chips
                  hide-details></v-combobox>
    </v-col>
    <v-divider></v-divider>

    <template v-if="questionRequiresAnswers">
      <div class="title mt-4 mb-3">{{ $t('questions_management.answers') }}</div>

      <template v-if="type !== 'SCALE'">
        <draggable v-model="answers" @end="answerDragEnd"  :move="answerDragMove" handle=".handle">
          <template v-for="(answer, index) in answers">
            <v-row :key="answer.key">
              <v-list-item class="pl-0 py-0">
                <v-list-item-content class="py-0 align-start">
                  <v-col cols="12" sm="6">
                    <v-text-field outlined
                                  :label="$t('questions_management.answer')"
                                  v-model="answer.answerText"
                                  hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-combobox outlined
                                :label="$t('questions_management.answer_tags')"
                                :items="allTags"
                                v-model="answer.tags"
                                item-value="id"
                                item-text="description"
                                @input="tagsOnInput"
                                multiple
                                deletable-chips
                                chips
                                small-chips
                                hide-details></v-combobox>
                  </v-col>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn class="handle" outlined icon small v-on="on">
                        <v-icon>mdi mdi-drag-variant</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('questions_management.reorder_answer') }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="removeAnswer(index)" outlined icon small v-on="on">
                        <v-icon>remove</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('questions_management.delete_answer') }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-col cols="12" class="text-center" v-if="index < answers.length - 1">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </template>
        </draggable>
        <v-row>
          <v-col cols="12">
            <v-btn text block @click="addNewAnswer" color="secondary" small>
              <v-icon left>add</v-icon>
              {{ $t('questions_management.add_answer') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-if="type === 'SCALE'">
        <template v-for="(answer, index) in answers">
          <v-row :key="index">
            <v-list-item class="py-0 pr-0">
              <v-list-item-avatar color="accent" class="justify-center">
                <div class="headline white--text text-center">{{ answer.answerText }}</div>
              </v-list-item-avatar>
              <v-list-item-content class="py-0 align-start">
                <v-col cols="12">
                  <v-combobox outlined
                              :label="$t('questions_management.answer_tags')"
                              :items="allTags"
                              v-model="answer.tags"
                              multiple
                              deletable-chips
                              chips
                              small-chips
                              hide-details></v-combobox>
                </v-col>
              </v-list-item-content>
            </v-list-item>
            <v-col cols="12" class="text-center" v-if="index < answers.length - 1">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </template>

    </template>
  </div>
</template>

<script>
import { questionTypes, onlineTestQuestionTypes } from "@/config/parameters";
import tenantApi from "@/api/tenant";
import draggable from 'vuedraggable';

export default {
  name: 'EditQuestionForm',
  components: {
    draggable
  },
  props: ['job', 'onlineTest', 'isNew'],
  data() {
    return {
      allTags: [],
      questionId: -1,
      questionText: '',
      type: null,
      quiz: !!this.onlineTest,
      requiredTags: [],
      exclusiveTags: [],
      classificationTags: [],
      answers: [],
      answerKey: 0,
    }
  },
  watch: {
    type(t) {
      if (!this.checkIfQuestionNeedsAnswers(t)) {
        this.quiz = false;
      } else if (this.isNew) {
        // quiz is true by default in online test view
        this.quiz = !!this.onlineTest;
      }
      if (this.questionId === -1) {
        if (t === 'SCALE') {
          this.answers = JSON.parse(JSON.stringify(this.initialScaleAnswers));
        } else {
          this.answers = [];
        }
      }
    },
  },
  methods: {
    tagsOnInput(tags) {
      for (let i = 0; i < tags.length; i++) {
        const t = tags[i];
        if (!this.allTags.find(et => et.toLowerCase() === t.toLowerCase())) {
          this.allTags.push(t);
        }
      }
    },
    addNewAnswer() {
      this.answers.push({ answerText: '', tags: [], key: ++this.answerKey });
      for (let i = 0; i < this.answers.length; i++) {
        this.answers[i].order = i;
      }
    },
    removeAnswer(index) {
      this.answers.splice(index, 1);
      for (let i = 0; i < this.answers.length; i++) {
        this.answers[i].order = i;
      }
    },
    async createQuestion() {
      // create or update question
      const createdQuestion = await tenantApi.addQuestion({
        questionText: this.questionText,
        type: this.type,
        quiz: this.quiz,
        requiredTags: this.requiredTags,
        exclusiveTags: this.exclusiveTags,
        classificationTags: this.classificationTags,
        answers: this.checkIfQuestionNeedsAnswers(this.type) ? this.answers : [],
      });

      if (this.job && this.job.id) {
        await tenantApi.addQuestionToJob(createdQuestion.id, this.job.id);
      }

      return createdQuestion;
    },
    async updateQuestion() {
      return await tenantApi.updateQuestion(this.questionId, {
        questionText: this.questionText,
        quiz: this.quiz,
        requiredTags: this.requiredTags,
        exclusiveTags: this.exclusiveTags,
        classificationTags: this.classificationTags,
        answers: this.checkIfQuestionNeedsAnswers(this.type) ? this.answers : [],
      });
    },
    async handleSave(createCopy) {
      if (this.questionId === -1 || createCopy) {
        return this.createQuestion();
      } else {
        return this.updateQuestion();
      }
    },
    async setNewQuestion() {
      this.questionId = -1;
      this.questionText = '';
      this.type = null;
      this.requiredTags = [];
      this.exclusiveTags = [];
      this.classificationTags = [];
      this.answers = [];
      await this.reloadTags();
    },
    async setQuestionForEdit(question) {
      this.questionId = question.id;
      this.questionText = question.questionText;
      this.type = question.type;
      this.quiz = question.quiz;
      this.requiredTags = question.requiredTags.map(t => t.description);
      this.exclusiveTags = question.exclusiveTags.map(t => t.description);
      this.classificationTags = question.classificationTags.map(t => t.description);
      if (this.checkIfQuestionNeedsAnswers(question.type)) {
        if (question.type === 'SCALE') {
          // Use deep clone because tags are copied as reference with [...answers]
          if (question.answers && question.answers.length > 0) {
            this.answers = JSON.parse(JSON.stringify(question.answers));
          } else {
            this.answers = JSON.parse(JSON.stringify(this.initialScaleAnswers));
          }
        } else {
          this.answers = JSON.parse(JSON.stringify(question.answers));
        }
        for (const answer of this.answers) {
          answer.key = ++this.answerKey;
        }
      } else {
        this.answers = [];
      }
      await this.reloadTags();
    },
    checkIfQuestionNeedsAnswers(type) {
      return type === 'SELECT_MANY' || type === 'SELECT_ONE' || type === 'SCALE';
    },
    async reloadTags() {
      try {
        this.allTags = await tenantApi.getTags();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    answerDragEnd() {
      for (let i = 0; i < this.answers.length; i++) {
        this.answers[i].order = i;
      }
    },
    answerDragMove() {
      return true;
    },
  },
  computed: {
    isNewQuestion() {
      return this.questionId === -1;
    },
    questionRequiresAnswers() {
      return this.checkIfQuestionNeedsAnswers(this.type);
    },
    questionTypes() {
      return this.onlineTest ? onlineTestQuestionTypes : questionTypes;
    },
    initialScaleAnswers() {
      return [
        { answerText: '1', tags: [], order: 0 },
        { answerText: '2', tags: [], order: 1  },
        { answerText: '3', tags: [], order: 2  },
        { answerText: '4', tags: [], order: 3  },
        { answerText: '5', tags: [], order: 4  },
      ]
    }
  },
  async mounted() {
    await this.reloadTags();
  },
}
</script>

<style scoped>

</style>
