<template>
  <v-form ref="form">
    <v-text-field :label="$t('job_ads.name')" v-model="job.name"/>
    <v-textarea :label="$t('job_ads.description')" v-model="job.description"/>
    <v-autocomplete
      class="mt-0"
      :label="$t('job_ads.client')"
      v-model="job.customerId"
      :items="customers"
      item-text="name"
      item-value="id"
      clearable
    />
    <v-text-field :label="$t('job_ads.number_of_positions')" v-model="job.positions"/>
  </v-form>
</template>
<script>
export default {
  name: 'job-basic-info-form',
  props: {
    job: {
      type: Object,
      default: () => ({}),
    },
    customers: {
      type: Array,
      default: () => [],
    },
  }
}
</script>
