<template>
  <v-container pa-0 fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <div class="text-center pl-5 pr-5" :class="small ? 'pb-6' : 'pb-16'">
            <v-icon :size="small ? 60 : 100" color="accent">{{ icon }}</v-icon>
            <div :class="small ? 'title mt-4 mb-1' : 'headline my-5'" class="font-weight-bold">{{ title }}</div>
            <div :class="small ? 'body-1' : 'subtitle-1'" class="subtitle-1">{{ description }}</div>
            <v-btn v-if="!hideButton" @click="$reloadApp" class="mt-5" color="primary" text outlined><v-icon left>refresh</v-icon> {{ $t('reload') }}</v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NoContentView",
  props: ['title', 'description', 'icon', 'hideButton', 'small']
}
</script>

<style scoped>

</style>
