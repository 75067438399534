<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card :tile="$vuetify.breakpoint.xsOnly">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ isNew ? $t('applicant.dialog_new_title') : $t('applicant.dialog_edit_title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-form ref="form">
          <v-text-field :label="$t('applicant.first_name')" v-model="applicant.firstName" :rules="[$rules.required]"/>
          <v-text-field :label="$t('applicant.last_name')" v-model="applicant.lastName" :rules="[$rules.required]"/>
          <v-text-field :label="$t('applicant.email')" v-model="applicant.email" :rules="[$rules.required]"/>
          <v-text-field :label="$t('applicant.phone')" v-model="applicant.phone" :rules="[$rules.required]"/>
          <v-text-field :label="$t('applicant.address')" v-model="applicant.address" :rules="[$rules.required]"/>
          <v-text-field :label="$t('applicant.zip_code')" v-model="applicant.zipCode" :rules="[$rules.required]"/>
          <v-text-field :label="$t('applicant.city')" v-model="applicant.city" :rules="[$rules.required]"/>
          <v-select
            v-model="selectedCardsAndPermits"
            :items="cardsAndPermits"
            :label="$t('applicant.cards_and_permits')"
            :item-text="(item) => $te(`licenses.${item.code}`) ? $t(`licenses.${item.code}`) : item.code"
            item-value="code"
            multiple
            deletable-chips
            chips
            hide-details
            :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : ''"
          />
          <v-select
            v-model="selectedDrivingLicenses"
            :items="drivingLicenses"
            :label="$t('applicant.driving_licenses')"
            :item-text="(item) => $te(`licenses.${item.code}`) ? $t(`licenses.${item.code}`) : item.code"
            item-value="code"
            multiple
            deletable-chips
            chips
            hide-details
            :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : ''"
          />

          <div class="title mt-5">{{ $t('experience.title') }}</div>
          <template v-for="value in experienceValues">
            <v-card class="mb-2 option-card" :key="value.tempId">
              <v-card-text class="pb-0">
                <v-combobox :items="industries"
                            class="mt-1"
                            v-model="value.industry"
                            item-text="name"
                            item-value="code"
                            :label="$t('experience.industry_select')"></v-combobox>
                <v-slider v-model="value.years"
                          v-if="value.industry"
                          min="0"
                          max="6"
                          hide-details
                          tick-size="5"
                          ticks="always"
                          thumb-color="accent"
                          always-dirty>
                </v-slider>
                <template v-if="value.years === 0">
                  {{ $t('experience.none') }}
                </template>
                <template v-else-if="value.years === 6">
                  {{ $t('experience.over_x_years', {x: value.years - 1}) }}
                </template>
                <template v-else-if="value.industry">
                  {{ value.years }} {{ $tc('experience.year', value.years === 1 ? 1 : 2) }}
                </template>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn small color="red" text @click="removeWorkExperience(value.tempId)">Poista</v-btn>
              </v-card-actions>
            </v-card>
          </template>
          <v-btn small color="accent" depressed class="primary--text" block @click="addWorkExperience">
            <v-icon left>add</v-icon>
            {{ $t('experience.add_experience') }}
          </v-btn>

          <div class="title mt-5">{{ $t('education.title') }}</div>
          <template v-for="option in educationValues">
            <v-card class="mb-2 option-card" :key="option.id" :class="option.selected ? 'selected' : ''">
              <v-checkbox
                class="pa-2 ma-0"
                v-model="option.selected"
                hide-details
                :label="option.text"
              ></v-checkbox>
              <v-card-text v-if="option.selected && !['peruskoulu', 'lukio'].includes(option.value)">
                <v-combobox :items="industries"
                            class="mt-1"
                            v-model="option.industries"
                            item-text="name"
                            item-value="code"
                            multiple
                            hide-details
                            :label="$t('education.industry_select')"></v-combobox>
              </v-card-text>
            </v-card>
          </template>

        </v-form>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="close"
               :disabled="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="save"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { educationOptions } from '@/config/parameters.js';

export default {
  name: 'EditApplicantDialog',
  props: {
    industries: {
      type: Array,
      default: () => [],
    },
    licenses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      applicant: {},
      isNew: false,
      selectedCardsAndPermits: [],
      selectedDrivingLicenses: [],
      experienceValues: [],
      experienceTemplate: {
        years: null, industry: null, employer: '',
      },
      educationValues: [],
    };
  },
  computed: {
    cardsAndPermits() {
      return this.licenses.filter(l => l.category === 'LICENSE');
    },
    drivingLicenses() {
      return this.licenses.filter(l => l.category === 'DRIVING');
    },
  },
  methods: {
    addWorkExperience() {
      this.experienceValues.push(
        Object.assign({ tempId: this.experienceValues.length}, this.experienceTemplate)
      );
    },
    removeWorkExperience(tempId) {
      const i = this.experienceValues.findIndex(v => v.tempId === tempId);
      if (i > -1) {
        this.experienceValues.splice(i, 1);
      }
    },
    open(applicant) {
      this.applicant = applicant ? { ...applicant } : {};
      this.isNew = !applicant;
      this.selectedCardsAndPermits = this.applicant.licenses.map(l => l.license).filter(l => l.category === 'LICENSE').map(l => l.code);
      this.selectedDrivingLicenses = this.applicant.licenses.map(l => l.license).filter(l => l.category === 'DRIVING').map(l => l.code);

      this.experienceValues = this.applicant.experience.map((e, index) => ({
        years: e.years,
        industry: e.industry,
        employer: '',
        tempId: index,
        id: e.id,
      }));

      this.educationValues = educationOptions.map(e2 => {
        const e = { ...e2 };
        const aes = this.applicant.education.filter(ae => ae.educationLevel === e.value);
        e.industries = aes.map(ae => ae.industry).filter(ae => ae);
        e.selected = aes.length > 0;
        return e;
      });

      this.dialog = true;
    },
    close() {
      this.$refs.form.resetValidation();
      this.applicant = {};
      this.dialog = false;
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const education = [];
      this.educationValues.forEach(o => {
        if (o.selected) {
          if (o.industries && o.industries.length > 0) {
            o.industries.forEach(industry => {
              education.push({educationLevel: o.value, industryCode: industry.code});
            });
          } else {
            education.push({educationLevel: o.value, industryCode: null});
          }
        }
      });

      const experience = [];
      this.experienceValues.forEach(v => {
        if (v.industry && v.industry.code) {
          experience.push({years: v.years, industryCode: v.industry.code});
        }
      });

      this.$emit('save', {
        ...this.applicant,
        licenses: [
          ...this.selectedCardsAndPermits.map(l => ({code: l})),
          ...this.selectedDrivingLicenses.map(l => ({code: l})),
        ],
        experience,
        education,
      });
      this.close();
    },
  },
}
</script>

<style scoped>

</style>
