<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark :color="options.color">
        <v-toolbar-title class="white--text">{{ $t('edit_comment_dialog.title') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-textarea v-model="comment" class="mt-2" rows="3" :label="$t('comment')" />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="cancel()">{{ $t('edit_comment_dialog.cancel') }}</v-btn>
        <v-btn text :color="options.color" @click.native="agree()">{{ $t('edit_comment_dialog.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditCommentDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      comment: '',
      options: {
        color: 'primary',
        agree: this.$t('yes'),
        cancel: this.$t('no'),
        width: 440,
      }
    };
  },
  methods: {
    open(options, message) {
      options ||= {};
      this.dialog = true;
      this.options = { ...this.options, ...options };
      this.comment = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.resolve([true, this.comment]);
      this.$refs.form.resetValidation();
      this.comment = '';
      this.dialog = false;
    },
    cancel() {
      this.resolve([false, '']);
      this.comment = '';
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
}
</script>

<style scoped>

</style>
