<template>
  <hb-basic-page :title="$t('questions_management.title')"
                 :loading="loading"
                 :search-title="`${$t('questions_management.search')}...`"
                 @handle-search="handleSearch"
                 :fill-height="loading || (questions && questions.length === 0)"
                 name="questions-management-view">

    <template v-if="!loading && questions && questions.length > 0">
      <v-col cols="12" class="pt-0 flex-shrink-1" :style="{height: $vuetify.breakpoint.xsOnly ? '0' : '60px'}">
        <v-fab-transition>
          <v-btn
            @click="addNewQuestion"
            color="primary"
            dark
            v-if="ready"
            :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('questions_management.add_new_question') }}</span>
          </v-btn>
        </v-fab-transition>
      </v-col>

      <v-col cols="12" md="8" offset-md="2" xl="6" offset-xl="3">
        <template v-for="question in filteredQuestions">
          <QuestionCard :question="question"
                        :key="question.id"
                        :hide-details="hideQuestionDetails"
                        :selected-answers="[]"
                        @handle-edit="handleQuestionEdit"
                        @handle-delete="handleDeleteQuestion"
                        :delete-text="$t('questions_management.remove_question')" />
        </template>
      </v-col>
    </template>

    <v-col cols="12" v-if="!loading && questions && questions.length === 0">
      <NoContentView :title="$t('questions_management.no_questions')" :description="$t('questions_management.no_questions_help')" icon="mdi mdi-magnify-scan" />
      <div class="text-center" style="margin-top: -50px; padding-bottom: 50px;">
        <v-fab-transition>
          <v-btn
            @click="addNewQuestion"
            color="primary"
            dark
            v-if="ready"
            :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('questions_management.add_new_question') }}</span>
          </v-btn>
        </v-fab-transition>
      </div>
    </v-col>

    <EditQuestionDialog ref="editQuestionDialog" @question-updated="questionUpdated" @question-created="questionCreated" @question-copy-created="questionCopied" />
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

  </hb-basic-page>
</template>

<script>
  import EditQuestionDialog from "@/components/Tenant/EditQuestionDialog";
  import QuestionCard from "@/components/Tenant/QuestionCard";
  import tenantApi from "@/api/tenant";
  import NoContentView from "@/components/NoContentView";
  import ConfirmDialog from '@/components/ConfirmDialog';

  export default {
    name: 'QuestionsManagementView',
    components: {NoContentView, QuestionCard, EditQuestionDialog, ConfirmDialog},
    data() {
      return {
        ready: this.$vuetify.breakpoint.smAndUp,
        loading: true,
        subtleLoading: false,
        hideQuestionDetails: false,
        questions: [],
        questionSearchPhrase: '',
      }
    },
    methods: {
      questionCopied(question) {
        this.questions.push(question);
        this.$showSuccessNotification(this.$t('questions_management.question_copied'))
      },
      questionCreated(question) {
        this.questions.push(question);
        this.$showSuccessNotification(this.$t('questions_management.question_created'))
      },
      questionUpdated(question) {
        const index = this.questions.findIndex(q => q.id === question.id);
        this.questions.splice(index, 1, question);
        this.$showSuccessNotification(this.$t('questions_management.question_updated'))
      },
      async handleDeleteQuestion(question) {
        if (question.jobAds.length > 0) {
          const confirm = await this.$refs.confirmDialog.open(
            this.$t('questions_management.confirm_delete_question_title'),
            this.$t('questions_management.confirm_delete_question_message'),
            {
              color: 'error',
              agree: this.$t('questions_management.confirm_delete_question_confirm'),
              cancel: this.$t('questions_management.confirm_delete_question_cancel')
          });
          if (!confirm) {
            return;
          }
        }

        this.loading = true;

        try {
          await tenantApi.deleteQuestion(question.id);
          this.questions = this.questions.filter(q => q.id !== question.id);
          this.$showSuccessNotification(this.$t('questions_management.question_removed'))
        } catch (error) {
          this.$handleApiError(error);
        }

        this.loading = false;
      },
      handleQuestionEdit(question) {
        this.$refs.editQuestionDialog.open(question);
      },
      handleSearch(searchPhrase) {
        this.questionSearchPhrase = searchPhrase;
      },
      addNewQuestion() {
        this.$refs.editQuestionDialog.open();
      },
      async reloadData() {
        this.loading = true;
        try {
          this.questions = await tenantApi.getQuestions();
        } catch (error) {
          this.$handleApiError(error);
        }
        this.loading = false;
      },
    },
    computed: {
      filteredQuestions() {
        const searchPhrases = this.questionSearchPhrase.toLowerCase().split(' ');
        return this.questions.filter(q => {
          const found = [];
          searchPhrases.forEach(searchPhrase => {
            found.push((q.questionText.toLowerCase().includes(searchPhrase) ||
              q.answers.flatMap(a => a.tags).some(t => t.toLowerCase().startsWith(searchPhrase)) ||
              q.requiredTags.some(t => t.description.toLowerCase().startsWith(searchPhrase)) ||
              q.exclusiveTags.some(t => t.description.toLowerCase().startsWith(searchPhrase)) ||
              q.classificationTags.some(t => t.description.toLowerCase().startsWith(searchPhrase))
            )
            && !q.quiz)
          });
          return found.indexOf(false) === -1;
        });
      },
    },
    mounted() {
      this.reloadData();
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.ready = true
        }, 300);
      } else {
        this.ready = true;
      }
    }
  }
</script>

<style scoped>

</style>
