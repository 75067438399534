import axios from 'axios';

/*
 * Used for api calls for current user.
 * for example: profile, get my tenants, ...
 */
const userApi = {

  async createSubscription(newSubscription) {
    await axios.post('/api/user/subscription', newSubscription);
  },
  async enableNotifications() {
    await axios.post('/api/user/subscription/enable');
  },
  async disableNotifications() {
    await axios.post('/api/user/subscription/disable');
  },
  async getMe() {
    const response = await axios.get('/api/user');
    return response.data;
  },
  async updateUserInfo(email, firstName, lastName, phone) {
    const data = {
      email,
      firstName,
      lastName,
      phone,
    }
    const response = await axios.post('/api/user', data);
    return response.data;
  },
  async uploadProfileImage(file, imageFilename) {
    const formData = new FormData();
    formData.append('file', file, imageFilename);
    const response = await axios.post(`/api/user/image`, formData);
    return response.data;
  },
};

export default userApi;
