<template>
  <v-list-item class="px-0">
    <v-list-item-content>
      <v-list-item-title class="body-1 font-weight-bold text-wrap">{{ label }}:</v-list-item-title>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title class="body-1 font-weight-regular text-right text-wrap"><a :href="value" target="_blank">{{ value }}</a></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'JobDetailLink',
  props: ['label', 'value'],
}
</script>

<style scoped>

</style>
