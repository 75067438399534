<template>
  <div style="container-type: inline-size;">
    <v-select
      :items="allIndustries"
      item-text="name"
      item-value="code"
      v-model="selectedIndustries"
      clearable
      counter
      v-if="!hideSelect"
      multiple
      :label="$t('industries.select_education')"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "HbEducationSelector",
  props: ['selectedItems', 'options', 'hideSelect', 'allIndustries'],
  data() {
    return {
      loading: false,
      selectedIndustries: [],
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
    selectedIndustries(to, from) {
      if (to.length > from.length) {
        const added = to.filter(i => !from.includes(i));
        const newSelectedItems = [...this.selectedItems, ...added].filter((v, i, a) => a.indexOf(v) === i);

        if (JSON.stringify(newSelectedItems) !== JSON.stringify(this.selectedItems)) {
          this.$emit('change', newSelectedItems);
        }
      } else if (to.length < from.length) {
        const removed = from.filter(i => !to.includes(i));
        const newSelectedItems = this.selectedItems.filter(i => !removed.includes(i));

        if (JSON.stringify(newSelectedItems) !== JSON.stringify(this.selectedItems)) {
          this.$emit('change', newSelectedItems);
        }
      }
    }
  },
  async mounted() {
    this.selectedIndustries = this.selectedItems ? [...new Set(this.selectedItems)] : [];
  }
}
</script>

<style scoped>

</style>
