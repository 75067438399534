<template>
  <hb-basic-page title="Tenant testisivu"
                 :loading="loading"
                 name="tenant-test-view">

    <v-col cols="12">

      <v-alert type="info" :value="true" prominent text>
        Tenanttijuttujen testausta varten, sivu on sellanen että pitää olla tenantti valittuna että toimii ja toiminnot tapahtuvat vain kyseisen tenantin dataan.
      </v-alert>

      <v-card>
        <v-card-text>
          <v-text-field label="Viesti"
                        v-model="msg"
                        hint="Kirjoita viesti ja lähetä se niin se näkyy vain tällä tenantilla."
                        persistent-hint>
            <template v-slot:append-outer>
              <v-btn color="primary" @click="saveNewHello" :icon="$vuetify.breakpoint.xsOnly">
                <template v-if="$vuetify.breakpoint.xsOnly"><v-icon>send</v-icon></template>
                <template v-else>Lähetä</template>
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>
      </v-card>

      <v-list color="transparent" class="mt-5">
        <v-divider></v-divider>
        <template v-for="hello in hellos" >
          <v-list-item :key="hello.id">
            <v-list-item-title>{{ hello.msg }}</v-list-item-title>
            <v-list-item-action>
              <v-btn icon small @click="deleteHello(hello)"><v-icon>delete</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`${hello.id}_divider`"></v-divider>
        </template>
      </v-list>

    </v-col>

  </hb-basic-page>
</template>

<script>
  import tenantApi from "../../api/tenant";

  export default {
    name: 'MessageView',
    data() {
      return {
        loading: false,
        subtleLoading: false,
        hellos: [],
        msg: '',
      }
    },
    methods: {
      async saveNewHello() {
        try {
          const newHello = await tenantApi.addHello(this.msg);
          this.msg = '';
          this.hellos.unshift(newHello);
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async loadHellos() {
        const hellos = await tenantApi.getHellos();
        this.hellos = hellos.reverse();
      },
      async deleteHello(hello) {
        await tenantApi.deleteHello(hello.id);
        this.hellos = this.hellos.filter(h => h.id !== hello.id);
      }
    },
    mounted() {
      this.loadHellos();
    }
  }
</script>

<style scoped>

</style>
