<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <template v-slot:activator="{ on }">
      <v-fab-transition>
        <v-btn
          v-on="on"
          color="primary"
          dark
          v-show="$vuetify.breakpoint.smAndUp || ready"
          :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">person_add</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('user_management.create_new_user') }}</span>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('user_management.create_new_user') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">

        <v-subheader class="pl-0">{{ $t('user_management.user_info') }}</v-subheader>
        <v-text-field
          :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed]"
          validate-on-blur
          class="mt-0 email"
          :label="$t('user_management.email')"
          autocomplete="username"
          type="text"
          v-model="username"
        ></v-text-field>

        <v-subheader class="pl-0">{{ $t('user_management.user_roles') }}</v-subheader>
        <v-btn-toggle v-if="$vuetify.breakpoint.smAndUp" v-model="roleSet" mandatory multiple class="elevation-0">
          <template v-for="role in roles">
            <v-btn :key="role" text outlined :color="$randomizeColorFromString(role, 60, 45)" :value="role">{{ role }}</v-btn>
          </template>
        </v-btn-toggle>

        <v-select
          v-if="$vuetify.breakpoint.xsOnly"
          v-model="roleSet"
          :items="roles"
          attach
          chips
          :label="$t('user_management.roles')"
          multiple
          style="width: 100%;"
        >
          <template v-slot:selection="data">
            <v-chip
              :selected="data.selected"
              close
              small
              outline
              @input="removeRole(data.item)"
              class="ml-0"
              :color="$randomizeColorFromString(data.item, 60, 45)"
            >
              <strong>{{ data.item }}</strong>
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-action>
              <v-icon v-if="roleSet.findIndex(r => r === data.item) > -1" :color="$randomizeColorFromString(data.item, 60, 45)">check_box</v-icon>
              <v-icon v-else :color="$randomizeColorFromString(data.item, 60, 45)">check_box_outline_blank</v-icon>
            </v-list-item-action>
            <v-list-item-title :style="{color: $randomizeColorFromString(data.item, 60, 45)}">{{ data.item }}</v-list-item-title>
          </template>
        </v-select>

        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">Tarkista, että olet täyttänyt kaikki pakolliset kentät.</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveUser"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import adminApi from "../../api/admin";

  export default {
    name: "AddUserDialog",
    props: {
      roles: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        dialog: false,
        username: '',
        showPassword: false,
        roleSet: this.roles.length > 0 ? [this.roles[0]] : [],
        showAlert: false,
        ready: false,
      }
    },
    methods: {
      removeRole (item) {
        this.roleSet.splice(this.roleSet.indexOf(item), 1);
        this.roleSet = [...this.roleSet];
      },
      async saveUser() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        const user = {
          roleSet: this.roleSet,
          username: this.username,
          email: this.username,
          type: 'COGNITO',
        };

        try {
          await adminApi.createUser(user);
          this.dialog = false;
          this.$showSuccessNotification(this.$t('user_management.user_created_success'));
        } catch (err) {
          this.$handleApiError(err);
        }
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.username = '';
          this.showPassword = false;
          this.roleSet = [];
          this.showAlert = false;
          this.$refs.form.reset();
          this.$emit('on-dismiss');
        }
      }
    },
    mounted() {
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.ready = true
        }, 300);
      } else {
        this.ready = true;
      }
    }
  }
</script>

<style scoped>

</style>
