<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card :tile="$vuetify.breakpoint.xsOnly">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ isNew ? $t('customers.dialog_new_title') : $t('customers.dialog_edit_title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-form ref="form">
          <v-text-field :label="$t('customers.name')" v-model="customer.name" :rules="[$rules.required, uniqueCustomerName]"/>
          <v-text-field :label="$t('customers.business_id')" v-model="customer.businessId"/>
          <v-autocomplete
            class="mt-0"
            :label="$t('customers.industry')"
            v-model="customer.industryCode"
            :items="industries"
            item-text="name"
            item-value="code"
            clearable
          />
        </v-form>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="close"
               :disabled="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="save"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddOrEditCustomerDialog',
  props: {
    industries: {
      type: Array,
      default: () => [],
    },
    customers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      customer: {},
      isNew: true,
    };
  },
  methods: {
    open(customer) {
      this.customer = customer ? { ...customer } : {};
      this.isNew = !customer;
      this.dialog = true;
    },
    close() {
      this.$refs.form.resetValidation();
      this.customer = {};
      this.dialog = false;
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit('save', this.customer);
      this.close();
    },
    uniqueCustomerName(value) {
      return !this.customers.find(c => c.name === value && c.id !== this.customer.id) || this.$t('customers.name_exists');
    },
  },
}
</script>

<style scoped>

</style>
